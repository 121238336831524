
import Vue from "vue";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters } from "vuex";
import { ModuleNames } from "@/store";

export default Vue.extend({
  name: "TokenSuccessView",
  data: function () {
    return {
      faCheckCircle,
    };
  },
  computed: {
    ...mapGetters(ModuleNames.auth, { isAuthenticated: "isAuthenticated" }),
  },
  components: {
    FontAwesomeIcon,
  },
});
