
import Vue from "vue";

export default Vue.extend({
  name: "ClarityCheckbox",
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
    label: { type: String, default: "" },
  },
  data: () => ({
    model: false,
  }),
  watch: {
    model() {
      this.$emit("input", this.model);
    },

    value() {
      this.model = this.value;
    },
  },
  created(): void {
    this.model = this.value;
  },
});
