export default {
  welcome: "Witaj",
  rememberMe: "Pamiętaj mnie",
  logIn: "Zaloguj się",
  signUp: "Zarejestruj się",
  logInSlogan:
    "Odkryj moc tworzenia z CraftyDesigns - Zaloguj się i zacznij projektować już dziś!",
  registerSlogan:
    "Dołącz do kreatywnej społeczności CraftyDesigns - Zarejestruj się teraz i uwolnij swoją wyobraźnię!",
  slogan:
    "Stwórz własne projekty z CraftyDesigns - Twoja kreatywność nie zna granic!",
  yourProject: "Twój projekt",
  addText: "Dodaj tekst",
  fontSize: "Rozmiar czcionki",
  font: "Czcionka",
  enterYourOwnText: "Wprowadź własny tekst",
  settings: "Ustawienia",
  image: "Zdjęcie",
  shape: "Kształt",
  text: "Tekst",
  layers: "Warstwy",
  download: "Pobierz",
  GI: "IG",
  GL: "ŁG",
  Yes: "Tak",
  No: "Nie",
  Confirm: "Zatwierdź",
  save: "Zapisz",
  cancel: "Anuluj",
  close: "Zamknij",
  error: "Błąd",
  categories: "Kategorie",
  categoryName: "Nazwa kategorii",
  addCategory: "Dodaj kategorie",
  addField: "Dodaj pole",
  sale: "Wyprzedaż",
  popular: "Popularne",
  newCollection: "Nowa kolekcja",
  areYouSure: "Czy jesteś pewien?",
  emailHasBeenSent: "Email został wysłany.<br>Sprawdź swoją pocztę",
  returnedError: "Wystąpił błąd, spróbuj ponownie później",
  formContainsErrors: "Formularz zawiera błędy, popraw i spróbuj ponownie",
  dataUpdated: "Aktualizacja danych zakończona pomyślnie",
  details: "szczegóły",
  addTo: "Dodaj do",
  total: "razem",
  from: "z",
  goToNextPage: "Przejdź do następnej strony",
  goToFirstPage: "Przejdź do pierwszej strony",
  goToPrevPage: "Przejdź do poprzedniej strony",
  goToLastPage: "Przejdź do ostatniej strony",
  goToPage: "Przejdź do {page} strony",
  errors: {
    "auth/user-not-found": "Nie znaleziono użytkownika o podanym adresie email",
    "auth/wrong-password": "Podane hasło jest niepoprawne",
    "auth/email-already-in-use": "Istnieje już konto dla podanego adresu email",
  },
  noResultsFound: "Brak wyników",
  changePassword: {
    description:
      "Na Twój adres e-mail zostanie wysłana wiadomość e-mail umożliwiająca zmianę hasła. Czy chcesz kontynuować?",
    yesContinue: "Tak, kontynuuj",
  },
  validation: {
    required: "To pole jest obowiązkowe",
    email: "Niepoprawny adres email",
    sameAsPassword: "Podane hasła nie są takie same",
    minLength: "Wprowadzona wartość jest zbyt krótka",
    minValue: "Wprowadzona wartość jest zbyt mała",
    url: "Wprowadzono niepoprawny url",
    isValidPhone: "Niepoprawny format numeru telefonu",
    isValidNip: "Niepoprawny format numeru NIP",
    isValidPostalCode: "Niepoprawny format kodu pocztowego",
  },
  or: "lub",
  navbar: {
    creators: "Kreatory",
    placeCards: "Winietki",
    creator: "Kreator",
    home: "Główna",
    orders: "Moje zakupy",
    users: "Użytkownicy",
    warehouse: "Magazyn",
    yourProfile: "Konto",
    settings: "Ustawienia",
    signOut: "Wyloguj",
    products: "Produkty",
    search: "Wyszukiwarka",
    login: "Logowanie",
    games: "Gry",
    account: "Konto",
  },
  breadcrumbs: {},
  login: {
    loginFormHeader: "Zaloguj się na swoje konto",
    yourEmail: "Twój email",
    yourName: "Twoje imię i nazwisko",
    yourPassword: "Twoje hasło",
    forgotYourPassword: "Nie pamiętasz hasła?",
    login: "Zaloguj",
    dontHaveAccount: "Nie masz konta?",
  },
  name: "Imię i nazwisko",
  email: "Email",
  password: "Hasło",
  confirmPassword: "Powtórz hasło",
  register: {
    registerFormHeader: "Rejestracja",
    readAndAccept: "Zapoznałem się i akceptuję ",
    term: "regulamin",
    and: "i",

    privacyPolicy: "politykę prywatności",
    save: "Zapisz",
    haveAccount: "Masz konto?",
    success: "Użytkownik został pomyślnie zarejestrowany",
  },
  account: {
    header: "Konto",
    settings: "Ustawienia",
    email: "Email",
    name: "Imię i nazwisko",
    phone: "Numer telefonu",
    info: "Informacje",
    password: "Hasło",
    repeatPassword: "Powtórz hasło",
    savePassword: "Zapisz hasło",
    changePassword: "Zmień hasło",
    admin: "Admin",
    moderator: "Moderator",
    user: "Użytkownik",
    role: "Rola użytkownika",
    notApproved:
      "Twoje konto nie zostało jeszcze zatwierdzone przez administratora",
  },
  users: {
    addNewUser: "Dodaj nowego użytkownika",
    name: "Imię i nazwisko",
    role: "Rola",
    createdAt: "Data utworzenia",
    approved: "Zatwierdzony",
    approvedAt: "Data potwierdzenia",
    email: "Email",
    phone: "Telefon",
    approveUser: "Chcesz zatwierdzić tego użytkownika?",
    yesApprove: "Tak, zatwierdź",
    userHasBeenApproved: "Użytkownik został zatwierdzony",
    userHasBeenDeleted: "Użytkownik został usunięty",
    yesDelete: "Tak, usuń",
    deleteUser: "Chcesz usunąć tego użytkownika?",
  },
  "Required moderator role": "Wymagana rola moderatora",
  "Required admin role": "Wymagana rola admina",
  "User Not found.": "Nie znaleziono użytkownika",
  "Invalid Password!": "Niepoprawne hasło",
  "Failed! Email is already in use!":
    "Istnieje już konto dla podanego adresu email",
  detectedAdBlockTitle: "Wygląda na to, że blokujesz reklamy",
  detectedAdBlockDesc:
    "Dzięki reklamom możesz bezpłatnie korzystać z serwisu i czytać stworzone przez nas treści. Rozważ wyłączenie blokady dla naszego serwisu. Dziękujemy!",
  cv: "Cv",
  "Place cards": "Winietki",
  "Business cards": "Wizytówki",
  exploreOur: "Poznaj nas",
  aboutAs: "O nas",
  terms: "Regulaminy",
  term: "Regulamin",
  contact: "Kontakt",
  shippingAndPayments: "Wysyłka i płatności",
  allRightsReserved: "Wszelkie prawa zastrzeżone",
  shop: "Sklep",
  fields: "Pola",
  products: "Produkty",
  search: "Szukaj",
  type: "Typ",
  fieldName: "Nazwa",
  description: "Opis",
  options: "Opcje",
  updateField: "Zaktualizuj pole",
  addNewField: "Dodaj nowe pole",
  addNewCoupon: "Dodaj nowy kod",
  addNewProduct: "Dodaj nowy produkt",
  addNewBlog: "Dodaj nowy artykuł",
  addNewHeader: "Dodaj nowy slajd",
  addNewPage: "Dodaj nową stronę",
  updateProduct: "Zaktualizuj produkt",
  updateHeader: "Zaktualizuj slajd",
  updateCoupon: "Zaktualizuj kod",
  updateBlog: "Zaktualizuj artykuł",
  updatePage: "Zaktualizuj stronę",
  removeCoupon: "Usuń kod",
  removeHeader: "Usuń slajd",
  removeField: "Usuń pole",
  removeProduct: "Usuń produkt",
  removeBlog: "Usuń artykuł",
  removePage: "Usuń stronę",
  markAsDelivered: "Oznacz jako dostarczone",
  cancelOrder: "Anuluj zamówienie",
  areYouSureCancelOrder: 'Czy na pewno chcesz anulować zamówienie "{name}"?',
  areYouSureMarkAsDelivered:
    'Czy na pewno chcesz oznaczyć zamówienie "{name}" jako dostarczone?',
  areYouSureRemoveBlog: 'Czy na pewno chcesz usunąć artykuł "{name}"?',
  areYouSureRemovePage: 'Czy na pewno chcesz usunąć stronę "{name}"?',
  areYouSureRemoveHeader: 'Czy na pewno chcesz usunąć slajd "{name}"?',
  areYouSureRemoveCoupon: 'Czy na pewno chcesz usunąć kod "{name}"?',
  areYouSureRemoveField: 'Czy na pewno chcesz usunąć pole "{name}"?',
  areYouSureRemoveProduct: 'Czy na pewno chcesz usunąć produkt "{name}"?',
  add: "Dodaj",
  addedNewField: "Dodano nowe pole",
  addedNewBlog: "Dodano nowy artykuł",
  addedNewPage: "Dodano nową stronę",
  addedNewProduct: "Dodano nowy produkt",
  addedNewHeader: "Dodano nowy slajd",
  addedNewCoupon: "Dodano nowy kod",
  removedField: 'Usunięto pole "{name}"',
  removedHeader: 'Usunięto slajd "{name}"',
  removedProduct: 'Usunięto produkt "{name}"',
  removedImage: 'Usunięto zdjecie "{name}"',
  removedCoupon: 'Usunięto kod "{name}"',
  removedBlog: 'Usunięto artykuł "{name}"',
  removedPage: 'Usunięto stronę "{name}"',
  addProduct: "Dodaj produkt",
  addBlog: "Dodaj artykuł",
  addPage: "Dodaj stronę",
  title: "Tytuł",
  category: "Kategoria",
  tags: "Tagi",
  quantity: "Ilość",
  price: "Cena",
  changePrice: "Zmiana wpływa na cenę produktu",
  yes: "tak",
  no: "nie",
  select: "Zaznacz",
  images: "Zdjęcia",
  home: "Główna",
  addToCart: "Dodaj do koszyka",
  removeOption: "Usuń opcję",
  promotionalPrice: "Cena promocyjna",
  startDate: "Data początkowa",
  endDate: "Data końcowa",
  code: "Kod",
  codes: "Kody",
  orders: "Zamówienia",
  discountType: "Typ rabatu",
  discountValue: "Wartość rabatu",
  "Order not paid.": "Zamówienie nie zostało opłacone.",
  orderStatus: {
    NEW: "Utworzono zamówienie",
    PAYMENT_ERROR: "Błąd podczas płatności",
    PAYMENT_CONFIRMED: "Zamówienie opłacone",
    PAYMENT_PENDING: "Przetwarzanie płatności",
    PAYMENT_NEW: "Rozpoczęto płatność",
    PARCEL_RECEIVED: "Przesyłka dostarczona",
    PARCEL_DELIVERED: "Przesyłka dostarczona",
    IN_DELIVERY: "W drodze",
    PACKING: "Pakowanie",
    SHIPMENT_NUMBER_ADDED: "Dodano numer przesyłki",
    CANCELED: "Anulowano",
  },
};
