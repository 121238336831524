var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"py-12 bg-white sm:py-16 lg:py-20"},[_c('div',{staticClass:"px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"},[(_vm.pagesIsLoading)?[_c('img',{staticClass:"block w-16 h-16 mx-auto my-10",attrs:{"src":"/images/loading.svg","alt":"Ładowanie"}})]:(_vm.page)?_c('div',{staticClass:"max-w-3xl mx-auto xl:max-w-4xl"},[_c('div',{staticClass:"flex justify-end"},[(_vm.page && _vm.showEditButton && !_vm.page.protected)?_c('router-link',{staticClass:"hover:text-gray-600 mr-4 ml-auto",attrs:{"to":{
            name: 'account-shop-pages-add',
            query: {
              duplicate: _vm.page._id,
            },
          }}},[_vm._v("dodaj podobny ")]):_vm._e(),(_vm.page && _vm.showEditButton)?_c('router-link',{staticClass:"hover:text-gray-600 mr-0",attrs:{"to":{
            name: 'account-shop-pages-edit',
            params: { id: _vm.page._id },
            query: {
              return_to_preview: true,
            },
          }}},[_vm._v("edytuj ")]):_vm._e()],1),_c('h1',{staticClass:"text-3xl font-bold text-gray-900 mt-7 sm:text-4xl xl:text-5xl font-pj"},[_vm._v(" "+_vm._s(_vm.page.title)+" ")]),_c('p',{staticClass:"text-sm font-normal text-gray-600 mt-7 font-pj"},[_vm._v(" Zaktualizowano: "+_vm._s(_vm.page.updatedAt ? new Date(_vm.page.updatedAt).toLocaleString() : "")+" ")]),_c('div',{staticClass:"mt-10"},[_c('SvgIcon',{staticClass:"w-auto h-4 text-gray-300",staticStyle:{"width":"172px"},attrs:{"icon":"separator"}})],1),(_vm.page.image)?_c('div',{staticClass:"relative table mx-auto mt-16"},[_vm._m(0),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.page.image.url),expression:"page.image.url"}],staticClass:"relative rounded-xl w-auto mx-auto max-w-full",attrs:{"alt":_vm.page.title + ' - img'}})]):_vm._e(),(_vm.page.content)?_c('div',{staticClass:"mt-10 page-content",domProps:{"innerHTML":_vm._s(_vm.page.content)}}):_vm._e()]):_c('div',{staticClass:"max-w-3xl mx-auto xl:max-w-4xl col-span-6 text-center"},[_c('FontAwesomeIcon',{staticClass:"w-10 h-10 mx-auto text-red-500",attrs:{"icon":_vm.faTimesCircle}}),_c('h1',{staticClass:"mt-4 text-2xl font-bold text-gray-900"},[_vm._v(" 404. Szukana strona nie istnieje. ")]),_c('p',{staticClass:"mt-2 text-sm font-normal text-gray-600"},[_vm._v(" Wróć do "),_c('router-link',{staticClass:"font-bold rounded hover:underline focus:outline-none",attrs:{"to":"/"}},[_vm._v("strony głównej ")])],1)],1)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"absolute -inset-2"},[_c('div',{staticClass:"w-full h-full mx-auto opacity-30 blur-lg filter colour-linear-gradient"})])
}]

export { render, staticRenderFns }