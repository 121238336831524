import type { Commit, Dispatch } from "vuex";
import { Page } from "@/types/main";

import PageService from "@/services/page.service";

export enum PageActions {
  FETCH_PAGES = "fetchPages",
  ADD_PAGE = "addPage",
  UPDATE_PAGE = "updatePage",
  DELETE_PAGE = "deletePage",
}

export enum PageMutations {
  SET_PAGES = "setPages",
  SET_PAGES_IS_LOADING = "setPagesIsLoading",
}

export interface PageState {
  pages: Array<Page>;
  pagesIsLoading: boolean;
}

export const state = (): PageState => ({
  pages: [],
  pagesIsLoading: false,
});

export const getters = {
  pages: (state: PageState) => state.pages,
  pagesIsLoading: (state: PageState) => state.pagesIsLoading,
};

export const actions = {
  async [PageActions.FETCH_PAGES]({
    commit,
  }: {
    commit: Commit;
  }): Promise<void> {
    try {
      commit(PageMutations.SET_PAGES_IS_LOADING, true);
      const response = await PageService.getPages();
      commit(PageMutations.SET_PAGES, response.pages);
    } finally {
      commit(PageMutations.SET_PAGES_IS_LOADING, false);
    }
  },
  async [PageActions.ADD_PAGE](
    { dispatch }: { dispatch: Dispatch },
    payload: Page
  ) {
    await PageService.addPage(payload);
    await dispatch(PageActions.FETCH_PAGES);
  },
  async [PageActions.UPDATE_PAGE](
    { dispatch }: { dispatch: Dispatch },
    payload: Page
  ) {
    await PageService.updatePage(payload);
    await dispatch(PageActions.FETCH_PAGES);
  },
  async [PageActions.DELETE_PAGE](
    { dispatch }: { dispatch: Dispatch },
    payload: Page
  ) {
    await PageService.deletePage(payload);
    await dispatch(PageActions.FETCH_PAGES);
  },
};

export const mutations = {
  [PageMutations.SET_PAGES](state: PageState, payload: Array<Page>) {
    state.pages = payload;
  },
  [PageMutations.SET_PAGES_IS_LOADING](state: PageState, payload: boolean) {
    state.pagesIsLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
