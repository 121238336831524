
import Vue, { PropType } from "vue";
import { formatPrice } from "@/helpers/shop.helper";
import { OrderCartItem, ShopProduct } from "@/types/shop";
import {
  faShoppingCart,
  faCreditCard,
  faCheckCircle,
  faShippingFast,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import { ModuleNames } from "@/store";
import { convertStringToUrl } from "@/helpers/url";

export default Vue.extend({
  name: "AccountMyShippingCartItem",
  components: {},
  computed: {
    ...mapGetters(ModuleNames.shop, { shopProducts: "shopProducts" }),
    product(): ShopProduct | undefined {
      return this.shopProducts?.find(
        (p: ShopProduct) => p._id === this.cartItem?.product
      );
    },
  },
  data: function () {
    return {
      faShoppingCart,
      faCreditCard,
      faCheckCircle,
      faShippingFast,
      faBoxOpen,
    };
  },
  props: {
    cartItem: { type: Object as PropType<OrderCartItem> },
  },
  methods: {
    convertStringToUrl,
    formatPrice,
    getUnitPrice(item: OrderCartItem): string {
      return formatPrice(item.price / item.quantity);
    },
  },
});
