import Vue from "vue";
import Vuex, { Commit, type Dispatch } from "vuex";
import auth, { AuthActions } from "@/store/auth";
import user from "@/store/user";
import game from "@/store/game";
import creator from "@/store/creator";
import { i18n } from "@/main";
import { Route } from "vue-router/types/router";
import { Breadcrumb, Header } from "@/types/main";
import shop from "@/store/shop";
import cart from "@/store/cart";
import order from "@/store/order";
import ShopService from "@/services/shop.service";
import blog from "@/store/blog";
import page from "@/store/page";

export enum ModuleNames {
  auth = "auth",
  user = "user",
  game = "game",
  creator = "creator",
  shop = "shop",
  cart = "cart",
  order = "order",
  blog = "blog",
  page = "page",
}

Vue.use(Vuex);
export const RootMutations = {
  SET_LAST_VISITED_ROUTE: "setLastVisitedRoute",
  SET_IS_LOADING: "setIsLoading",
  SET_IS_OPEN_CART: "setIsOpenCart",
  SET_BREADCRUMBS: "setBreadcrumbs",
  SET_HEADERS: "setHeaders",
};
export const RootActions = {
  FETCH_HEADERS: "fetchHeaders",
  ADD_HEADER: "addHeader",
  UPDATE_HEADER: "updateHeader",
  UPDATE_HEADERS: "updateHeaders",
  DELETE_HEADER: "deleteHeader",
  CLOSE_CART: "closeCart",
  OPEN_CART: "openCart",
  UPDATE_BREADCRUMBS: "updateBreadcrumbs",
  CORRECT_BREADCRUMBS_LABEL: "correctBreadcrumbsLabel",
};

export interface RootState {
  lastVisitedRoute: string;
  logo: string;
  companyNameNav: string;
  companyName: string;
  description: string;
  isLoading: boolean;
  isOpenCart: boolean;
  breadcrumbs: Array<Breadcrumb>;
  headers: Array<Header>;
}

export const state = (): RootState => ({
  lastVisitedRoute: "",
  companyNameNav: "Domowe szycie<br>u Ani",
  companyName: "Domowe szycie u Ani",
  description:
    "Domowe szycie u Ani. Scrunchie, artykuły dla dzieci - dekoracje, poduszki minky, koce, worki na zabawki, pieluszki i wiele innych. Realizujemy indywidualne zamówienia.",
  logo: "/logo-sm.png",
  isLoading: false,
  isOpenCart: false,
  breadcrumbs: [],
  headers: [],
});
export const getters = {
  isOpenCart: (state: RootState) => state.isOpenCart,
  isLoading: (state: RootState) => state.isLoading,
  breadcrumbs: (state: RootState) => state.breadcrumbs,
  headers: (state: RootState) =>
    [...state.headers]?.sort((a: Header, b: Header) => a.order - b.order),
};
const store = new Vuex.Store({
  state,
  getters,
  mutations: {
    [RootMutations.SET_LAST_VISITED_ROUTE](state, payload: string) {
      state.lastVisitedRoute = payload;
    },
    [RootMutations.SET_IS_LOADING](state, payload: boolean) {
      state.isLoading = payload;
    },
    [RootMutations.SET_IS_OPEN_CART](state, payload: boolean) {
      state.isOpenCart = payload;
    },
    [RootMutations.SET_BREADCRUMBS](state, payload) {
      state.breadcrumbs = payload;
    },
    [RootMutations.SET_HEADERS](state, payload) {
      state.headers = payload;
    },
  },
  actions: {
    [RootActions.OPEN_CART]({ commit }: { commit: Commit }) {
      commit(RootMutations.SET_IS_OPEN_CART, true);
    },
    [RootActions.CLOSE_CART]({ commit }: { commit: Commit }) {
      commit(RootMutations.SET_IS_OPEN_CART, false);
    },
    [RootActions.CORRECT_BREADCRUMBS_LABEL](
      { commit }: { commit: Commit },
      breadcrumbs: Array<{ link: string; label: string }>
    ) {
      commit(RootMutations.SET_BREADCRUMBS, breadcrumbs);
    },
    [RootActions.UPDATE_BREADCRUMBS](
      { commit }: { commit: Commit },
      route: Route
    ) {
      const breadcrumbs: Array<Breadcrumb> = [];
      const path = route.path;
      if (path !== "/" && path !== "") {
        const routes = path.replace("/", "").split("/");

        if (routes.length >= 1) {
          let link = "";
          routes.forEach((item: string) => {
            link = link + "/" + item;
            breadcrumbs.push({
              label: i18n.t("navbar." + item) as string,
              link: link,
            });
          });
        }
      }
      commit(RootMutations.SET_BREADCRUMBS, breadcrumbs);
    },
    async [RootActions.FETCH_HEADERS]({ commit }: { commit: Commit }) {
      const response = await ShopService.getHeaders();
      commit(RootMutations.SET_HEADERS, response.headers);
    },
    async [RootActions.ADD_HEADER](
      { dispatch }: { dispatch: Dispatch },
      payload: Header
    ) {
      await ShopService.addHeader(payload);
      await dispatch(RootActions.FETCH_HEADERS);
    },
    async [RootActions.UPDATE_HEADER](
      { dispatch }: { dispatch: Dispatch },
      payload: Header
    ) {
      await ShopService.updateHeader(payload);
      await dispatch(RootActions.FETCH_HEADERS);
    },
    async [RootActions.UPDATE_HEADERS](
      { dispatch }: { dispatch: Dispatch },
      payload: Array<Header>
    ) {
      await ShopService.updateHeaders(payload);
      await dispatch(RootActions.FETCH_HEADERS);
    },
    async [RootActions.DELETE_HEADER](
      { dispatch }: { dispatch: Dispatch },
      payload: Header
    ) {
      await ShopService.deleteHeader(payload);
      await dispatch(RootActions.FETCH_HEADERS);
    },
  },
  modules: {
    auth,
    user,
    game,
    creator,
    shop,
    cart,
    order,
    blog,
    page,
  },
});

export async function initAuth() {
  return store.dispatch(`${ModuleNames.auth}/${AuthActions.AUTH}`);
}

export default store;
