import { Order, ShopProduct, ShopProductVariant } from "@/types/shop";

function getStockStatus(
  product: ShopProduct | ShopProductVariant | undefined
): number | undefined {
  if (product) {
    if (product?.quantity !== undefined && product?.quantity > 9) {
      return 3;
    }
    if (product?.quantity !== undefined && product?.quantity > 5) {
      return 2;
    }
    if (product?.quantity !== undefined && product?.quantity > 0) {
      return 1;
    }
    if (product?.quantity !== undefined && product?.quantity === 0) {
      return 0;
    }
  }
  return undefined;
}

function getStockStatusLabel(
  product: ShopProduct | ShopProductVariant | undefined
): string {
  const stockStatus = getStockStatus(product);
  if (stockStatus === 3) {
    return `Duża dostępność (${product?.quantity} szt)`;
  }
  if (stockStatus === 2) {
    return `Średnia dostępność (${product?.quantity} szt)`;
  }
  if (stockStatus === 1) {
    return `Mała dostępność (${product?.quantity} szt)`;
  }
  if (stockStatus === 0) {
    return "Chwilowo niedostępne";
  }
  return "";
}

function getPrice(
  product: ShopProduct | ShopProductVariant | undefined
): number | undefined {
  if (!product) return undefined;
  const price =
    product?.promotionalPrice && product?.promotionalPrice !== product?.price
      ? product?.promotionalPrice
      : product?.price;
  if (!price) return undefined;
  return price;
}

function getPriceLabel(
  product: ShopProduct | ShopProductVariant | undefined
): string | undefined {
  const price = getPrice(product);
  if (!price) return undefined;
  return formatPrice(price);
}

function getStandardPriceLabel(
  product: ShopProduct | ShopProductVariant | undefined
): string | undefined {
  const price = getStandardPrice(product);
  if (!price) return undefined;
  return formatPrice(price);
}

function getStandardPrice(
  product: ShopProduct | ShopProductVariant | undefined
): number | undefined {
  return product?.price;
}

function formatPrice(price: number): string {
  return (Math.round(price * 100) / 100).toFixed(2) + " zł";
}

function getSortedByPriceVariants(
  product: ShopProduct
): Array<ShopProductVariant> {
  if (!product?.variants) return [];
  return [...product?.variants].sort((a, b) => {
    const priceA = a.promotionalPrice
      ? a.promotionalPrice
      : a.price
      ? a.price
      : product?.price || 0;
    const priceB = b.promotionalPrice
      ? b.promotionalPrice
      : b.price
      ? b.price
      : product?.price || 0;
    return priceA - priceB;
  });
}

function getDefaultSelectedVariant(
  product: ShopProduct | undefined
): ShopProductVariant | undefined {
  if (product?.variants?.length) {
    const sortedVariants = getSortedByPriceVariants(product);
    const variant = sortedVariants.find((v) =>
      v.quantity !== undefined ? v.quantity > 0 : product?.quantity > 0
    );
    if (variant) return variant;
    return product?.variants[0];
  }
  return undefined;
}

function getProductItemPrice(
  product: ShopProduct,
  variant?: ShopProductVariant
): number | undefined {
  if (variant) {
    const price = getPrice(variant);
    if (price) return price;
  }
  return getPrice(product);
}

function getStandardProductItemPrice(
  product: ShopProduct,
  variant?: ShopProductVariant
): number | undefined {
  if (variant) {
    const price = getStandardPrice(variant);
    if (price) return price;
  }
  return getStandardPrice(product);
}

function getMinPrice(
  product: ShopProduct,
  variant?: ShopProductVariant
): number | undefined {
  if (variant && variant.fields) {
    const selectedVariantKey = Object.values(variant.fields).sort().join(".");
    const minVariantPrice = product?.minVariantPrice?.[selectedVariantKey];
    if (minVariantPrice) {
      return minVariantPrice;
    }
    const variantPrices = [];

    if (variant?.price) variantPrices.push(variant?.price);
    if (variant?.promotionalPrice)
      variantPrices.push(variant?.promotionalPrice);
    const variantsMin = Math.min(...variantPrices);
    if (variantsMin) return variantsMin;
  }
  if (product?.minPrice) return product.minPrice;
  const prices = [];
  if (product?.price) prices.push(product?.price);
  if (product?.promotionalPrice) prices.push(product?.promotionalPrice);
  const min = Math.min(...prices);
  if (min) return min;
  return undefined;
}

function getDiscount(
  price?: number,
  standardPrice?: number
): number | undefined {
  if (
    price !== undefined &&
    standardPrice !== undefined &&
    price < standardPrice
  ) {
    return Math.round((1 - price / standardPrice) * 10000) / 100;
  }
  return undefined;
}

function getDeliveredStatus(statuses: Order["statuses"]) {
  return statuses.find(
    (s) =>
      s.status === "PARCEL_RECEIVED" ||
      s.status === "PARCEL_DELIVERED" ||
      s.status === "INPOST_delivered"
  );
}

function getCanceledStatus(statuses: Order["statuses"]) {
  return statuses.find((s) => s.status === "CANCELED");
}

function getPaymentConfirmedStatus(statuses: Order["statuses"]) {
  return statuses.find((s) => s.status === "PAYMENT_CONFIRMED");
}

function getPendingStatus(statuses: Order["statuses"]) {
  return statuses.find(
    (s) =>
      s.status === "PAYMENT_PENDING" ||
      s.status === "PAYMENT_NEW" ||
      s.status === "NEW"
  );
}

export {
  getPendingStatus,
  getCanceledStatus,
  getPaymentConfirmedStatus,
  getDeliveredStatus,
  getDiscount,
  getMinPrice,
  getStandardProductItemPrice,
  getProductItemPrice,
  getSortedByPriceVariants,
  getDefaultSelectedVariant,
  getPriceLabel,
  getStandardPriceLabel,
  getStockStatus,
  getStockStatusLabel,
  getPrice,
  getStandardPrice,
  formatPrice,
};
