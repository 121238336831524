export default {
  welcome: "Welcome",
  rememberMe: "Remember Me",
  logIn: "Log in",
  signUp: "Sign up",
  logInSlogan:
    "Unlock your creative power with CraftyDesigns - Log in and start designing today!",
  registerSlogan:
    "Join the creative community at CraftyDesigns - Register now and unleash your imagination!",
  slogan:
    "Create your own designs with CraftyDesigns - Unleash your creativity today!",
  enterYourOwnText: "Enter your own text",
  yourProject: "Your project",
  settings: "Settings",
  images: "Images",
  shapes: "Shapes",
  texts: "Texts",
  layers: "Layers",
  save: "Save",
  cancel: "Cancel",
  close: "Close",
  error: "Error",
  areYouSure: "Are you sure?",
  emailHasBeenSent: "Email has been sent.<br>Check your mail",
  returnedError: "An error occurred, please try again later",
  formContainsErrors: "The form contains errors, correct it and try again",
  dataUpdated: "Data update completed successfully",
  addTo: "Add to",
  errors: {
    "auth/user-not-found": "No user with the given email address was found",
    "auth/wrong-password": "The password was incorrect",
    "auth/email-already-in-use":
      "There is already an account for the given email address",
  },
  changePassword: {
    description:
      "An email will be sent to your email address that allows you to change your password. Do you want to continue?",
    yesContinue: "Yes, continue",
  },
  validation: {
    required: "This field is required",
    email: "Invalid email address",
    sameAsPassword: "The entered passwords are not the same",
    minLength: "Entered value is too short",
  },
  navbar: {
    home: "Home",
    orders: "Orders",
    users: "Users",
    warehouse: "Warehouse",
    yourProfile: "Your Profile",
    settings: "Settings",
    signOut: "Sign out",
    products: "Products",
    search: "Search",
    login: "Login",
    games: "Games",
  },
  login: {
    loginFormHeader: "Login To Your Account",
    yourEmail: "Your email",
    yourPassword: "Your password",
    forgotYourPassword: "Forgot Your Password?",
    login: "Login",
    dontHaveAccount: "Don't have an account?",
  },
  name: "Name",
  email: "Email",
  password: "Password",
  confirmPassword: "Confirm password",
  register: {
    registerFormHeader: "Register",
    save: "Save",
    haveAccount: "You do have an account?",
    success: "User was registered successfully",
    readAndAccept: "I have read and accept ",
    termsAndConditions:
      "the terms and conditions as well as the privacy policy.",
  },
  account: {
    header: "Account",
    settings: "Settings",
    email: "Email",
    name: "Name",
    phone: "Phone",
    info: "Information",
    password: "Password",
    repeatPassword: "Repeat password",
    savePassword: "Save Password",
    changePassword: "Change Password",
    admin: "Admin",
    moderator: "Moderator",
    user: "User",
    role: "User role",
    notApproved: "Your account has not been approved by the admin yet",
  },
  users: {
    addNewUser: "Add new user",
    name: "Name",
    role: "Role",
    createdAt: "Created at",
    approved: "Approved",
    approvedAt: "Approved At",
    email: "Email",
    phone: "Phone",
    approveUser: "Do you want to approved this user?",
    yesApprove: "Yes, approve",
    userHasBeenApproved: "The user has been approved",
    userHasBeenDeleted: "The user has been deleted",
    yesDelete: "Yes, delete",
    deleteUser: "Do you want to delete this user?",
  },
  add: "Add",
  exploreOur: "Explore our",
};
