var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item)?_c('li',{staticClass:"py-5",class:{ flex: _vm.mode === 'full' }},[(_vm.mode === 'full')?[_c('div',{staticClass:"flex-shrink-0 flex justify-center items-center"},[_c('img',{staticClass:"object-cover w-16 h-16 rounded-lg",attrs:{"src":_vm.item.product?.images?.length
            ? _vm.item.product.images[0].thumb_url
              ? _vm.item.product.images[0].thumb_url
              : _vm.item.product.images[0].url
            : '/images/default-image.png',"alt":_vm.item.product.title + ' - img'}})]),_c('div',{staticClass:"flex-1 flex ml-5"},[_c('div',{staticClass:"relative sm:grid sm:grid-cols-2 sm:gap-x-5 items-center w-full"},[_c('div',{staticClass:"pr-9 sm:pr-5"},[_c('p',{staticClass:"text-base font-bold text-gray-900 leading-5 mb-1"},[_vm._v(" "+_vm._s(_vm.item.product.title)+" ")]),(_vm.variantLabel)?_c('p',{staticClass:"mt-1.5 font-medium text-xs text-gray-500 mb-0"},[_vm._v(" "+_vm._s(_vm.variantLabel)+" ")]):_vm._e()]),_c('div',{staticClass:"flex items-end justify-between mt-3 sm:justify-end sm:pr-10 sm:items-center sm:mt-0"},[_c('p',{staticClass:"flex-shrink-0 w-20 mb-1 text-base font-bold text-left text-gray-900 sm:text-right sm:order-2 sm:ml-2"},[_vm._v(" "+_vm._s(_vm.totalPriceLabel)+" ")]),_c('div',{staticClass:"sm:order-1 flex items-center mb-1"},[_c('ClaritySelect',{staticClass:"!mt-0 w-16 mr-2",attrs:{"items":_vm.quantityItems,"filter":false,"size":"xxs"},on:{"input":_vm.updateQuantity},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('div',{staticClass:"truncate text-sm"},[_vm._v(" x "+_vm._s(_vm.formatPrice(_vm.item.unitPrice))+" ")])],1),_c('div',{staticClass:"absolute top-0 right-0 h-full sm:flex justify-center items-center"},[_c('FontAwesomeIcon',{staticClass:"p-1 w-4 h-4 mb-1 text-gray-400 hover:text-gray-900 cursor-pointer transition-all duration-200",attrs:{"icon":_vm.faTrashAlt},on:{"click":function($event){$event.stopPropagation();return _vm.removeCartItem(_vm.item)}}})],1)])])])]:[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0 flex justify-center items-center"},[_c('img',{staticClass:"object-cover w-16 h-16 rounded-lg",attrs:{"src":_vm.item.product?.images?.length
              ? _vm.item.product.images[0].thumb_url
                ? _vm.item.product.images[0].thumb_url
                : _vm.item.product.images[0].url
              : '/images/default-image.png',"alt":_vm.item.product.title + ' - img'}})]),_c('div',{staticClass:"flex items-center justify-between flex-1 ml-5 space-x-5"},[_c('div',{staticClass:"flex flex-col justify-between flex-1"},[_c('p',{staticClass:"text-sm font-bold text-gray-900 mb-0 leading-4"},[_vm._v(" "+_vm._s(_vm.item.product.title)+" ")]),(_vm.variantLabel)?_c('p',{staticClass:"mt-1.5 font-medium text-xs text-gray-500 mb-0"},[_vm._v(" "+_vm._s(_vm.variantLabel)+" ")]):_vm._e()]),_c('div',{staticClass:"flex flex-col items-end justify-between"},[_c('FontAwesomeIcon',{staticClass:"p-1 w-4 h-4 text-gray-400 hover:text-gray-900 cursor-pointer transition-all duration-200",attrs:{"icon":_vm.faTrashAlt},on:{"click":function($event){$event.stopPropagation();return _vm.removeCartItem(_vm.item)}}})],1)])]),_c('div',{staticClass:"flex items-center justify-between mt-1"},[_c('ClaritySelect',{staticClass:"!mt-0 w-16 mr-5",attrs:{"items":_vm.quantityItems,"filter":false,"size":"xxs"},on:{"input":_vm.updateQuantity},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('div',{},[_vm._v("x "+_vm._s(_vm.unitPriceLabel))]),_c('div',{staticClass:"mr-0 ml-auto"},[_vm._v(_vm._s(_vm.totalPriceLabel))])],1)]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }