import { axiosInstance } from "@/helpers/auth";
import { CreatorCategory, CreatorSettings } from "@/types/creator";

const CreatorService = {
  getCreatorsList: async function (): Promise<{
    creators: Array<CreatorSettings>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/creators`,
    });
  },
  getCreatorCategories: async function (): Promise<{
    categories: Array<CreatorCategory>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/creators/categories`,
    });
  },
};
export default CreatorService;
