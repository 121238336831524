
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import { getErrorMessage } from "@/helpers/validation";
import { ModuleNames } from "@/store";
import { UserActions } from "@/store/user";
import AppNavBar from "@/components/app/AppNavBar.vue";
import SoftInput from "@/components/soft/SoftInput.vue";
import SoftCheckbox from "@/components/soft/SoftCheckbox.vue";
import SoftButton from "@/components/soft/SoftButton.vue";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import ClarityInput from "@/components/clarity/ClarityInput.vue";
import ClarityCheckbox from "@/components/clarity/ClarityCheckbox.vue";

export default Vue.extend({
  name: "RegisterView",
  components: {
    ClarityCheckbox,
    ClarityInput,
    ClarityButton,
  },
  // middleware: ["is-guest"],
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(7),
    },
    confirmPassword: {
      sameAsPassword: sameAs("password"),
    },
    acceptTermsAndConditions: {
      required: (val) => {
        return val === true;
      },
    },
  },
  data: (): {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    formError: string;
    isLoading: boolean;
    acceptTermsAndConditions: boolean;
  } => ({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTermsAndConditions: false,
    formError: "",
    isLoading: false,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, { isAuthenticated: "isAuthenticated" }),
  },
  watch: {
    isAuthenticated(): void {
      this.checkThatAuthenticated();
    },
  },
  mounted(): void {
    this.checkThatAuthenticated();
  },
  methods: {
    ...mapActions(ModuleNames.user, [UserActions.REGISTER_NEW_USER]),
    getErrorMessage(validation: any): string {
      return getErrorMessage(validation);
    },
    async registerUser(): Promise<void> {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$noty.error(this.$t("formContainsErrors") as string);
        return;
      }
      this.isLoading = true;
      try {
        await this.registerNewUser({
          email: this.email,
          password: this.password,
          confirmPassword: this.confirmPassword,
          displayName: this.name,
        });
        this.$noty.success(this.$t("register.success") as string);
        this.$router.push({ name: "login" });
      } catch (e: any) {
        console.error(e);
        this.$noty.error(this.$t("formContainsErrors") as string);
        this.formError = this.$t(e.message) as string;
      } finally {
        this.isLoading = false;
      }
    },
    checkThatAuthenticated(): void {
      if (this.isAuthenticated) {
        this.$router.push({ name: "account" });
      }
    },
  },
});
