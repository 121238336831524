var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"py-12 bg-white sm:py-16 lg:py-20"},[_c('div',{staticClass:"px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"},[(_vm.blogsIsLoading)?[_c('img',{staticClass:"block w-16 h-16 mx-auto my-10",attrs:{"src":"/images/loading.svg","alt":"Ładowanie"}})]:[_vm._m(0),_c('div',{staticClass:"grid grid-cols-1 mx-auto mt-8 text-center sm:mt-16 sm:text-left sm:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-8 lg:gap-x-20"},_vm._l((_vm.blogs),function(blog){return _c('div',{key:blog._id,staticClass:"relative group"},[_c('router-link',{attrs:{"to":'/blog/' + blog._id + '-' + _vm.convertStringToUrl(blog.title)}},[_c('div',{staticClass:"overflow-hidden h-60 w-full rounded-2xl"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  blog.image
                    ? blog.image?.isNew
                      ? blog.image?.thumb_url
                      : blog.image?.url
                    : '/images/default-image.png'
                ),expression:"\n                  blog.image\n                    ? blog.image?.isNew\n                      ? blog.image?.thumb_url\n                      : blog.image?.url\n                    : '/images/default-image.png'\n                "}],staticClass:"object-cover h-full w-full transition-all duration-200 group-hover:opacity-70",attrs:{"alt":blog.title + ' - img'}})]),_c('p',{staticClass:"mt-6 text-sm font-normal text-gray-600 font-pj"},[_vm._v(" "+_vm._s(blog.createdAt ? new Date(blog.createdAt).toLocaleString() : "")+" ")]),_c('p',{staticClass:"mt-4 text-xl font-bold text-gray-900 font-pj line-clamp-2"},[_vm._v(" "+_vm._s(blog.title)+" ")])])],1)}),0)]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"max-w-md mx-auto text-center"},[_c('h2',{staticClass:"text-3xl font-bold text-gray-900 sm:text-4xl font-pj"},[_vm._v(" Blog ")]),_c('p',{staticClass:"mt-5 font-normal text-gray-600 font-pj"},[_vm._v(" Zapraszamy serdecznie do naszego bloga! Znajdziesz tu wiele ciekawych artykułów dotyczących dzieci, zdrowia, edukacji oraz wielu innych tematów związanych z maluchami. Zapraszamy do czytania! ")])])
}]

export { render, staticRenderFns }