import type { Commit } from "vuex";
import { CreatorCategory, CreatorSettings } from "@/types/creator";
import CreatorService from "@/services/creator.service";

export enum CreatorActions {
  UPDATE_CREATOR = "updateCreator",
  ADD_NEW_CREATOR = "addNewCreator",
  FETCH_CREATORS_LIST = "fetchCreatorsList",
  FETCH_CREATOR_CATEGORIES = "fetchCreatorCategories",
  CONFIRM_CREATOR = "confirmCreator",
  DELETE_CREATOR = "deleteCreator",
}

export enum CreatorMutations {
  SET_CREATORS_LIST = "setCreatorsList",
  SET_CREATOR_CATEGORIES = "setCreatorCategories",
}
export interface CreatorState {
  creators: Array<CreatorSettings>;
  creatorCategories: Array<CreatorCategory>;
}
export const state = (): CreatorState => ({
  creators: [],
  creatorCategories: [],
});

export const getters = {
  creators: (state: CreatorState) => state.creators,
  creatorCategories: (state: CreatorState) =>
    state.creatorCategories.map((i) => ({ ...i, name: i.title })),
};

export const actions = {
  async [CreatorActions.FETCH_CREATORS_LIST]({ commit }: { commit: Commit }) {
    const response = await CreatorService.getCreatorsList();
    commit(CreatorMutations.SET_CREATORS_LIST, response.creators);
  },
  async [CreatorActions.FETCH_CREATOR_CATEGORIES]({
    commit,
  }: {
    commit: Commit;
  }) {
    const response = await CreatorService.getCreatorCategories();
    commit(CreatorMutations.SET_CREATOR_CATEGORIES, response.categories);
  },
};

export const mutations = {
  [CreatorMutations.SET_CREATORS_LIST](
    state: CreatorState,
    payload: Array<CreatorSettings>
  ) {
    state.creators = payload;
  },
  [CreatorMutations.SET_CREATOR_CATEGORIES](
    state: CreatorState,
    payload: Array<CreatorCategory>
  ) {
    state.creatorCategories = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
