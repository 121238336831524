
import Vue, { PropType } from "vue";
import { CartItem, ShopProduct, ShopProductVariant } from "@/types/shop";
import { mapMutations } from "vuex";
import { ModuleNames } from "@/store";
import ClaritySelect from "@/components/clarity/ClaritySelect.vue";
import { formatPrice } from "@/helpers/shop.helper";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default Vue.extend({
  name: "ClarityCartItem",
  components: { ClaritySelect, FontAwesomeIcon },
  props: {
    item: {
      type: Object as PropType<CartItem>,
    },
    mode: { type: String, default: "" },
  },
  data() {
    return {
      quantity: 0,
      faTrashAlt,
    };
  },
  computed: {
    totalPrice(): number {
      return this.unitPrice * this.quantity;
    },
    totalPriceLabel(): string {
      return formatPrice(this.totalPrice);
    },
    unitPrice(): number {
      return this.item.unitPrice;
    },
    unitPriceLabel(): string | undefined {
      return formatPrice(this.unitPrice);
    },
    quantityItems(): Array<{ value: number; label: string }> {
      const quantity =
        this.item?.variant?.quantity !== undefined
          ? this.item?.variant?.quantity
          : this.item?.product.quantity;
      if (quantity) {
        return Array.from(Array(quantity + 1).keys()).map((i) => ({
          value: i,
          label: i.toString(),
        }));
      }
      return [];
    },
    variantLabel(): string {
      if (!this.item?.variantLabel) return "";
      return this.item?.variantLabel;
    },
  },
  methods: {
    formatPrice,
    ...mapMutations(ModuleNames.cart, ["updateCartItem", "removeCartItem"]),
    updateQuantity(value: number): void {
      if (this.item?.quantity !== value) {
        this.updateCartItem({
          ...this.item,
          quantity: value,
        });
      }
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(): void {
        this.quantity = this.item.quantity;
      },
    },
  },
});
