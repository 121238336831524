
import Vue from "vue";
import SvgIcon from "@/components/SvgIcon.vue";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { ModuleNames } from "@/store";
import ClarityCartItem from "@/components/clarity/ClarityCartItem.vue";
import { formatPrice } from "@/helpers/shop.helper";

export default Vue.extend({
  name: "ClarityCart",
  components: { ClarityCartItem, ClarityButton, SvgIcon },
  props: {},
  computed: {
    ...mapGetters({ isOpenCart: "isOpenCart" }),
    ...mapGetters(ModuleNames.cart, {
      cart: "cart",
      totalProductsPrice: "totalProductsPrice",
      maxProcessingTime: "maxProcessingTime",
    }),
    totalProductsPriceLabel(): string {
      return formatPrice(this.totalProductsPrice);
    },
  },
  methods: {
    ...mapActions(["openCart", "closeCart"]),
    clickOutside(): void {
      if (this.isOpenCart) this.closeCart();
    },
  },
  watch: {
    $route() {
      if (this.isOpenCart) this.closeCart();
    },
  },
});
