import { axiosInstance } from "@/helpers/auth";
import { Blog } from "@/types/main";

const BlogServices = {
  getBlogs: async function (): Promise<{
    blogs: Array<Blog>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/blogs`,
    });
  },
  addBlog: async function (payload: Blog): Promise<{
    category: Blog;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/blogs`,
      data: payload,
    });
  },
  updateBlog: async function (payload: Blog): Promise<{
    category: Blog;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/blogs/` + payload._id,
      data: payload,
    });
  },

  deleteBlog: async function (payload: Blog): Promise<void> {
    return await axiosInstance({
      method: "delete",
      url: `/api/blogs/` + payload._id,
    });
  },
};
export default BlogServices;
