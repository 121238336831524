import { User } from "@/types/user";
import { axiosInstance } from "@/helpers/auth";

const AuthService = {
  login: async function (payload: {
    email: string;
    password: string;
  }): Promise<{ accessToken: string; user: User }> {
    return axiosInstance({
      method: "post",
      url: `/api/auth/login`,
      data: payload,
    });
  },

  getCurrentUser: async function (): Promise<{ user: User }> {
    return axiosInstance.get(`/api/auth/user`);
  },
  resetPassword: async function (
    email: string
  ): Promise<{ users: Array<User> }> {
    return await axiosInstance({
      method: "put",
      url: `/api/auth/reset-password`,
      data: { email },
    });
  },
  createNewPassword: async function (payload: {
    email: string;
    token: string;
    password: string;
    confirmPassword: string;
  }): Promise<{ users: Array<User> }> {
    return await axiosInstance({
      method: "put",
      url: `/api/auth/new-password`,
      data: payload,
    });
  },
};
export default AuthService;
