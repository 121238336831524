import { axiosInstance } from "@/helpers/auth";
import {
  Coupon,
  DeliveryPoint,
  Order,
  ShopCategory,
  ShopField,
  ShopProduct,
} from "@/types/shop";
import { User } from "@/types/user";
import axios from "axios";
import { Header } from "@/types/main";

const ShopService = {
  refreshBeta: async function (): Promise<void> {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_BETA_API_URL}/api/shops/refresh`,
    });
  },
  getShopCategories: async function (): Promise<{
    categories: Array<ShopCategory>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/categories`,
    });
  },
  addShopCategory: async function (payload: ShopCategory): Promise<{
    categories: Array<ShopCategory>;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/shops/categories`,
      data: payload,
    });
  },
  updateShopCategory: async function (payload: ShopCategory): Promise<{
    categories: Array<ShopCategory>;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/shops/categories/` + payload._id,
      data: payload,
    });
  },
  updateShopCategories: async function (
    payload: Array<ShopCategory>
  ): Promise<{ categories: Array<ShopCategory> }> {
    return await axiosInstance({
      method: "put",
      url: `/api/shops/categories`,
      data: payload,
    });
  },
  deleteShopCategory: async function (
    payload: ShopCategory
  ): Promise<{ categories: Array<ShopCategory> }> {
    return await axiosInstance({
      method: "delete",
      url: `/api/shops/categories/` + payload._id,
    });
  },
  getShopFields: async function (): Promise<{
    fields: Array<ShopField>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/fields`,
    });
  },
  updateShopField: async function (payload: ShopField): Promise<{
    field: ShopField;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/shops/fields/` + payload._id,
      data: payload,
    });
  },
  addShopField: async function (payload: ShopField): Promise<{
    field: ShopField;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/shops/fields`,
      data: payload,
    });
  },
  deleteShopField: async function (payload: ShopField): Promise<void> {
    return await axiosInstance({
      method: "delete",
      url: `/api/shops/fields/` + payload._id,
    });
  },
  getShopProducts: async function (): Promise<{
    products: Array<ShopProduct>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/products`,
    });
  },
  getShopProductsBasic: async function (): Promise<{
    products: Array<ShopProduct>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/products/basic`,
    });
  },
  addShopProduct: async function (payload: ShopProduct): Promise<{
    product: ShopProduct;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/shops/products`,
      data: payload,
    });
  },
  updateShopProduct: async function (payload: ShopProduct): Promise<{
    product: ShopProduct;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/shops/products/` + payload._id,
      data: payload,
    });
  },
  deleteShopProduct: async function (payload: ShopProduct): Promise<{
    product: ShopProduct;
  }> {
    return await axiosInstance({
      method: "delete",
      url: `/api/shops/products/` + payload._id,
      data: payload,
    });
  },
  getCoupons: async function (): Promise<{
    coupons: Array<Coupon>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/coupons`,
    });
  },
  addCoupon: async function (payload: Coupon): Promise<{
    coupon: Coupon;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/shops/coupons`,
      data: payload,
    });
  },
  updateCoupon: async function (payload: Coupon): Promise<{
    coupon: Coupon;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/shops/coupons/` + payload._id,
      data: payload,
    });
  },
  deleteCoupon: async function (payload: Coupon): Promise<{
    coupon: Coupon;
  }> {
    return await axiosInstance({
      method: "delete",
      url: `/api/shops/coupons/` + payload._id,
      data: payload,
    });
  },
  couponIsActive: async function (code: string): Promise<{
    coupon: Coupon;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/coupons/is-active/` + code,
    });
  },
  createOrder: async function (payload: {
    price: number;
    delivery: string;
    deliveryPoint?: DeliveryPoint;
    cart: Array<{
      product: string;
      quantity: string;
      variantKeys?: { [key: string]: string };
      variantId?: string;
    }>;
    coupon?: string;
    recipient: Partial<User>;
    invoiceData: Partial<User>;
    acceptTermsAndConditions: boolean;
    maxProcessingTime: string;
  }): Promise<{ payment: { paymentId: string; redirectUrl: string } }> {
    return await axiosInstance({
      method: "post",
      url: `/api/orders`,
      data: payload,
    });
  },
  getMyOrders: async function (): Promise<{ orders: Array<Order> }> {
    return await axiosInstance({
      method: "get",
      url: `/api/orders/my`,
    });
  },
  tryAgain: async function (
    orderNumber: string
  ): Promise<{ payment: { paymentId: string; redirectUrl: string } }> {
    return await axiosInstance({
      method: "get",
      url: `/api/orders/retry-payment/` + encodeURIComponent(orderNumber),
    });
  },
  getOrder: async function (
    orderNumber: string,
    email: string
  ): Promise<{ order: Order }> {
    return await axiosInstance({
      method: "get",
      url:
        `/api/orders/` +
        encodeURIComponent(orderNumber) +
        "?email=" +
        encodeURIComponent(email),
    });
  },
  getOrders: async function (): Promise<{ orders: Array<Order> }> {
    return await axiosInstance({
      method: "get",
      url: `/api/orders`,
    });
  },

  getOrderInvoice: async function (orderId: number | string): Promise<any> {
    return await axiosInstance({
      method: "get",
      url: `/api/orders/${orderId}/invoice`,
      responseType: "blob",
    });
  },

  addShipmentNumber: async function (payload: {
    _id: string;
    shipmentNumber: string;
  }): Promise<{ order: Order }> {
    return await axiosInstance({
      method: "put",
      url: `/api/orders/${payload._id}/add-shipment-number`,
      data: payload,
    });
  },
  addOrderStatus: async function (payload: {
    _id: string;
    status: string;
  }): Promise<{ order: Order }> {
    return await axiosInstance({
      method: "put",
      url: `/api/orders/${payload._id}/add-status`,
      data: payload,
    });
  },
  getHeaders: async function (): Promise<{
    headers: Array<Header>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/headers`,
    });
  },
  addHeader: async function (payload: Header): Promise<{
    header: Header;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/shops/headers`,
      data: payload,
    });
  },
  updateHeader: async function (payload: Header): Promise<{
    header: Header;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/shops/headers/` + payload._id,
      data: payload,
    });
  },
  updateHeaders: async function (payload: Array<Header>): Promise<{
    headers: Array<Header>;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/shops/headers`,
      data: payload,
    });
  },
  deleteHeader: async function (payload: Header): Promise<{
    header: Header;
  }> {
    return await axiosInstance({
      method: "delete",
      url: `/api/shops/headers/` + payload._id,
      data: payload,
    });
  },
  sendUserMessage: async function (payload: {
    email: string;
    message: string;
  }): Promise<{
    header: Header;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/shops/message`,
      data: payload,
    });
  },
  getShopStats: async function (): Promise<{
    stats: { [key: string]: number };
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/shops/stats`,
    });
  },
};
export default ShopService;
