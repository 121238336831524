import { TokenService } from "@/services/token.service";
import axios from "axios";
import store, { ModuleNames } from "@/store";
import { AuthActions } from "@/store/auth";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
});

axiosInstance.interceptors.request.use(function (config: any) {
  const token = TokenService.getToken();
  if (token) config.headers.Authorization = "Bearer " + token;

  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    if (error.response.status === 401) {
      store.dispatch(`${ModuleNames.auth}/${AuthActions.LOGOUT}`, null, {
        root: true,
      });
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);
export { axiosInstance };
