import { axiosInstance } from "@/helpers/auth";
import { Game } from "@/types/game";

const GameService = {
  getGamesList: async function (): Promise<{ games: Array<Game> }> {
    return await axiosInstance({
      method: "get",
      url: `/api/games`,
    });
  },
};
export default GameService;
