var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('button',{staticClass:"flex items-center justify-center w-full text-base font-bold transition-all duration-200 border rounded-xl font-pj",class:[
      {
        'text-gray-600 bg-white border border-gray-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900':
          _vm.theme === 'stroke',
      },
      {
        'text-gray-900 transition-all duration-200 bg-white border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-gray-900 focus:ring-offset-2 focus:ring-white  hover:bg-gray-100':
          _vm.theme === 'stroke-2',
      },
      {
        'text-white bg-gray-900 border-transparent hover:bg-gray-600':
          _vm.theme === 'primary',
      },
      {
        'text-white bg-green-600 hover:bg-green-600': _vm.success,
      },
      { 'px-8 py-4 mt-5': _vm.size === 'md' },
      { 'px-3 py-2': _vm.size === 'sm' },
      { 'px-3 py-1': _vm.size === 'xs' },
      _vm.disabled || _vm.loading
        ? `opacity-60 cursor-not-allowed`
        : 'cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 active:opacity-85',
    ],attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.loading)?_c('img',{staticClass:"h-6 mx-auto",attrs:{"src":"/images/loading.svg","alt":"Ładowanie"}}):(_vm.success)?_c('div',{staticClass:"h-6 mx-auto"},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.faCheck()}})],1):_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }