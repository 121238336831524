
import Vue from "vue";
import { faTimesCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default Vue.extend({
  name: "TokenErrorView",
  data: function () {
    return {
      faInfoCircle,
      faTimesCircle,
    };
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    errorType(): string {
      return (this.$route?.query?.errorType as string) || "";
    },
  },
  methods: {},
});
