import type { Commit } from "vuex";
import ShopService from "@/services/shop.service";
import { Order } from "@/types/shop";
import Vue from "vue";
import { Dispatch } from "vuex";
import { ShopActions } from "@/store/shop";

export enum OrderActions {
  FETCH_MY_ORDERS = "fetchMyOrders",
  FETCH_ORDERS = "fetchOrders",
  ADD_SHIPMENT_NUMBER = "addShipmentNumber",
  ADD_ORDER_STATUS = "addOrderStatus",
}

export enum OrderMutations {
  SET_MY_ORDERS = "setMyOrders",
  SET_ORDERS_IS_LOADING = "setOrdersIsLoading",
  SET_ORDERS = "setOrders",
  UPDATE_ORDER = "updateOrder",
}

export interface OrderState {
  myOrders: Array<Order>;
  orders: Array<Order>;
  ordersIsLoading: boolean;
}

export const state = (): OrderState => ({
  myOrders: [],
  orders: [],
  ordersIsLoading: false,
});

export const getters = {
  myOrders: (state: OrderState) => state.myOrders,
  orders: (state: OrderState) => state.orders,
  ordersIsLoading: (state: OrderState) => state.ordersIsLoading,
};

export const actions = {
  async [OrderActions.FETCH_MY_ORDERS]({
    commit,
  }: {
    commit: Commit;
  }): Promise<void> {
    try {
      commit(OrderMutations.SET_ORDERS_IS_LOADING, true);
      const response = await ShopService.getMyOrders();
      commit(OrderMutations.SET_MY_ORDERS, response.orders);
    } finally {
      commit(OrderMutations.SET_ORDERS_IS_LOADING, false);
    }
  },
  async [OrderActions.FETCH_ORDERS]({
    commit,
  }: {
    commit: Commit;
  }): Promise<void> {
    try {
      commit(OrderMutations.SET_ORDERS_IS_LOADING, true);
      const response = await ShopService.getOrders();
      commit(OrderMutations.SET_ORDERS, response.orders);
    } finally {
      commit(OrderMutations.SET_ORDERS_IS_LOADING, false);
    }
  },
  async [OrderActions.ADD_SHIPMENT_NUMBER](
    { dispatch }: { dispatch: Dispatch },
    payload: {
      _id: string;
      shipmentNumber: string;
    }
  ): Promise<void> {
    await ShopService.addShipmentNumber(payload);
    await dispatch(OrderActions.FETCH_ORDERS);
  },
  async [OrderActions.ADD_ORDER_STATUS](
    { dispatch, commit }: { dispatch: Dispatch; commit: Commit },
    payload: {
      _id: string;
      status: string;
    }
  ): Promise<void> {
    try {
      commit(OrderMutations.SET_ORDERS_IS_LOADING, true);
      await ShopService.addOrderStatus(payload);
    } finally {
      await dispatch(OrderActions.FETCH_ORDERS);
    }
  },
};

export const mutations = {
  [OrderMutations.SET_MY_ORDERS](state: OrderState, payload: Array<Order>) {
    state.myOrders = payload;
  },
  [OrderMutations.SET_ORDERS](state: OrderState, payload: Array<Order>) {
    state.orders = payload;
  },
  [OrderMutations.SET_ORDERS_IS_LOADING](state: OrderState, payload: boolean) {
    state.ordersIsLoading = payload;
  },
  [OrderMutations.UPDATE_ORDER](state: OrderState, payload: Order) {
    const index = state.orders.findIndex((o) => o._id === payload._id);
    Vue.set(state.orders, index, payload);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
