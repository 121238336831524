
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { ModuleNames } from "@/store";
import { convertStringToUrl } from "@/helpers/url";
import { Page } from "@/types/main";
import SvgIcon from "@/components/SvgIcon.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { UserRole } from "@/types/user";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "PageView",
  metaInfo(): { titleTemplate: string; meta: any } {
    return {
      titleTemplate:
        (this.page?.title ? this.page?.title + " | " : "") + this.companyName,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: (
            (this.page?.title ? this.page?.title + " | " : "") +
            this.description
          ).substring(0, 160),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            (this.page?.title ? this.page?.title + " | " : "") +
            this.companyName,
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            (this.page?.title ? this.page?.title + " | " : "") +
            this.description,
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content:
            (this.page?.title ? this.page?.title + " | " : "") +
            this.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${window.location.href}`,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.img,
        },
      ],
    };
  },

  components: { SvgIcon, FontAwesomeIcon },
  methods: {
    convertStringToUrl,
  },
  data: function () {
    return { faTimesCircle };
  },
  computed: {
    ...mapGetters(ModuleNames.page, {
      pages: "pages",
      pagesIsLoading: "pagesIsLoading",
    }),
    ...mapGetters(ModuleNames.auth, { authUser: "authUser" }),
    ...mapState({ companyName: "companyName", description: "description" }),
    img(): string {
      let logo = this.page?.image?.url;
      const defaultLogo = window.location.origin + "/logo2.png";
      if (logo && logo.startsWith("/")) logo = window.location.origin + logo;
      return logo ? logo : defaultLogo;
    },

    showEditButton(): boolean {
      return this.authUser?.roles?.includes(UserRole.SHOP_MODERATOR);
    },
    page(): Page | undefined {
      return this.pages?.find(
        (page: Page) => "/" + this.$route.params.link === page.link
      );
    },
  },
});
