import VueCookies from "vue-cookies";

const TOKEN_KEY = "accessToken";

const TokenService = {
  getToken(): string {
    return VueCookies.get(TOKEN_KEY);
  },

  saveToken(accessToken: string): void {
    VueCookies.set(TOKEN_KEY, accessToken, "24h");
  },

  removeToken(): void {
    VueCookies.remove(TOKEN_KEY);
  },
};

export { TokenService };
