
import Vue, { PropType } from "vue";
import {
  formatPrice,
  getCanceledStatus,
  getDeliveredStatus,
  getPaymentConfirmedStatus,
} from "@/helpers/shop.helper";
import { Order } from "@/types/shop";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ModuleNames } from "@/store";
import AccountMyShippingCartItem from "@/components/AccountMyShippingCartItem.vue";
import ClarityInput from "@/components/clarity/ClarityInput.vue";
import OrderStatuses from "@/components/OrderStatuses.vue";
import { ModalWidth } from "@/types/main";
import { Modal } from "@/helpers/modal.helper";
import ClarityConfirmationModal from "@/components/clarity/modals/ClarityConfirmationModal.vue";
import { faBan, faTruck } from "@fortawesome/free-solid-svg-icons";

export default Vue.extend({
  name: "AccountShopOrder",
  components: {
    OrderStatuses,
    ClarityInput,
    AccountMyShippingCartItem,
    ClarityButton,
  },
  computed: {
    ...mapGetters(ModuleNames.auth, {
      isAuthenticated: "isAuthenticated",
      isShopModerator: "isShopModerator",
    }),
  },
  data: function () {
    return {
      shipmentNumber: "",
      isEditingShipmentNumber: true,
      isAddingShipmentNumber: false,
      isAddingStatus: false,
      modal: undefined as Modal | undefined,
    };
  },
  props: {
    order: { type: Object as PropType<Order> },
    mode: { type: String, default: "" },
  },
  methods: {
    getCanceledStatus,
    ...mapMutations(["setIsLoading"]),

    getDeliveredStatus,
    getPaymentConfirmedStatus,
    ...mapActions(ModuleNames.order, ["addShipmentNumber", "addOrderStatus"]),
    formatPrice,
    async cancelOrder(): Promise<void> {
      try {
        this.modal = new Modal({
          modal: ClarityConfirmationModal,
          paramsOrProps: {
            header: this.$t("cancelOrder"),
            message: this.$t("areYouSureCancelOrder", {
              name: this.order?.orderNumber,
            }),
            faIcon: faBan,
            iconClasses: "text-red-700",
          },
          width: ModalWidth.small,
        });
        await this.modal?.openModal();
        try {
          this.isAddingStatus = true;
          await this.addOrderStatus({
            _id: this.order._id,
            status: "CANCELED",
          });
        } catch (e: any) {
          console.error(e);
          this.$noty.error("Error: " + e.message);
        } finally {
          this.isAddingStatus = false;
        }
      } catch (e) {
        console.warn("Closed modal");
      } finally {
        this.modal = undefined;
      }
    },
    async markAsDelivered(): Promise<void> {
      try {
        this.modal = new Modal({
          modal: ClarityConfirmationModal,
          paramsOrProps: {
            header: this.$t("markAsDelivered"),
            message: this.$t("areYouSureMarkAsDelivered", {
              name: this.order?.orderNumber,
            }),
            faIcon: faTruck,
            iconClasses: "text-green-500",
          },
          width: ModalWidth.small,
        });
        await this.modal?.openModal();
        try {
          this.isAddingStatus = true;
          await this.addOrderStatus({
            _id: this.order._id,
            status: "PARCEL_DELIVERED",
          });
        } catch (e: any) {
          console.error(e);
          this.$noty.error("Error: " + e.message);
        } finally {
          this.isAddingStatus = false;
        }
      } catch (e) {
        console.warn("Closed modal");
      } finally {
        this.modal = undefined;
      }
    },

    async getInvoice(): Promise<void> {
      try {
        this.setIsLoading(true);
        window.open(this.order.invoiceUrl);
      } catch (e: any) {
        this.$noty.error("Error: " + this.$t(e.message));
      } finally {
        this.setIsLoading(false);
      }
    },
    async submitShipmentNumber(): Promise<void> {
      if (this.shipmentNumber === this.order.shipmentNumber) {
        this.$noty.error(
          "Wprowadzony numer przesyłki jest taki sam jak poprzedni"
        );
        return;
      }
      try {
        this.isAddingShipmentNumber = true;
        await this.addShipmentNumber({
          _id: this.order?._id,
          shipmentNumber: this.shipmentNumber,
        });
      } catch (e: any) {
        this.$noty.error("Error: " + e.message);
      } finally {
        this.isAddingShipmentNumber = false;
      }
    },
    editShipmentNumber(): void {
      this.isEditingShipmentNumber = true;
    },
  },
  watch: {
    order: {
      immediate: true,
      handler(): void {
        this.isEditingShipmentNumber = !this.order?.shipmentNumber;
        if (this.order?.shipmentNumber)
          this.shipmentNumber = this.order.shipmentNumber;
      },
    },
  },
});
