
import Vue from "vue";

export default Vue.extend({
  name: "SvgIcon",
  props: {
    icon: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    iconPath(): string {
      return `/icons.svg#${this.icon}`;
    },
  },
});
