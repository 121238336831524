
import Vue from "vue";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import ClarityModal from "@/components/clarity/modals/ClarityModal.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default Vue.extend({
  name: "ClarityConfirmationModal",
  props: {
    showClose: { type: Boolean, default: true },
    header: { type: String, default: "" },
    iconClasses: { type: String, default: "" },
    message: { type: String, default: "" },
    icon: { type: String, default: "" },
    faIcon: { type: Object, required: false },
    closeLabel: { type: String, default: "" },
    submitLabel: { type: String, default: "" },
    onConfirm: { type: Function },
  },
  components: {
    FontAwesomeIcon,
    ClarityModal,
    SvgIcon,
    ClarityButton,
  },
  methods: {
    async confirm(): Promise<void> {
      this.$emit("close");
      await this.onConfirm();
    },
    async close(): Promise<void> {
      this.$emit("close");
    },
  },
});
