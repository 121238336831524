import Vue, { AsyncComponent, ComponentOptions } from "vue";
import { ModalWidth } from "@/types/main";

interface ModalEvent {
  id: string;
  timestamp: number;
  canceled: boolean;
  name: string;
  ref: undefined | HTMLElement;
  state: boolean;
}

interface OpenModalProps {
  modal: string | typeof Vue | ComponentOptions<Vue> | AsyncComponent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsOrProps?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events?: any;
  width?: ModalWidth | string;
  overflow?: string;
}
export class Modal {
  VueInstance: typeof Vue;
  modal: string | typeof Vue | ComponentOptions<Vue> | AsyncComponent;
  paramsOrProps?: any;
  params?: any;
  events?: any;
  width?: ModalWidth | string;
  overflow?: string;

  constructor({
    modal,
    paramsOrProps,
    params,
    events,
    width = ModalWidth.big,
    overflow = "hidden",
  }: OpenModalProps) {
    this.VueInstance = new Vue();
    this.modal = modal;
    this.paramsOrProps = paramsOrProps ? paramsOrProps : {};
    this.params = params ? params : {};
    this.events = events ? events : {};
    this.width = width;
    this.overflow = overflow;
  }
  updateProp(key: string, value: any) {
    this.paramsOrProps[key] = value;
  }
  openModal<T = any>(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.paramsOrProps.onConfirm = (data: T) => {
        resolve(data);
      };

      this.events["closed"] = () => reject();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.VueInstance.$modal.show(
        this.modal,
        this.paramsOrProps,
        this.params,
        {
          ...this.events,
          ["opened"]: (event: ModalEvent) => {
            // eslint-disable-next-line
            const modalElement = event.ref!.querySelector(
              ".modal-wrapper"
            ) as HTMLElement;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modalElement.style.width = this.width;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modalElement.style.overflow = this.overflow;

            if (this.events["opened"]) this.events["opened"](event);
          },
          ["before-close"]: (event: ModalEvent) => {
            const container = document.getElementById("modals-container");
            // current modal is the last one opened
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (container!.children.length === 1) {
              document.body.style.overflow = "initial";
            }

            if (this.events["before-close"]) this.events["before-close"](event);
          },
        }
      );
    });
  }
}
