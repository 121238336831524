
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import { formatPrice } from "@/helpers/shop.helper";

import ShopService from "@/services/shop.service";
import ClarityRadio from "@/components/clarity/ClarityRadio.vue";
import ClarityOrderBreadcrumbs from "@/components/clarity/ClarityOrderBreadcrumbs.vue";
import ClarityUserForm from "@/components/forms/ClarityUserForm.vue";
import { User } from "@/types/user";
import { DeliveryPoint } from "@/types/shop";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import VueCookies from "vue-cookies";
import ClarityCheckbox from "@/components/clarity/ClarityCheckbox.vue";
import { getErrorMessage } from "@/helpers/validation";

export default Vue.extend({
  name: "FullCartView",
  components: {
    ClarityCheckbox,
    ClarityUserForm,
    ClarityOrderBreadcrumbs,
    ClarityRadio,
    FontAwesomeIcon,
    ClarityButton,
  },
  validations: {
    acceptTermsAndConditions: {
      required: (val) => {
        return val === true;
      },
    },
  },
  data: () => ({
    acceptTermsAndConditions: false,
    code: "",
    codeError: "",
    isCheckingCoupon: false,
    isEditingRecipient: false,
    isEditingInvoiceData: false,
    apaczkaMap: undefined,
    faEdit,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, {
      isAuthenticated: "isAuthenticated",
      authUser: "authUser",
    }),
    ...mapState(ModuleNames.cart, {
      deliveryOptions: "deliveryOptions",
      delivery: "delivery",
      deliveryPoint: "deliveryPoint",
    }),
    ...mapGetters(ModuleNames.cart, {
      cartPayload: "cartPayload",
      recipient: "recipient",
      invoiceData: "invoiceData",
      cart: "cart",
      totalProductsPrice: "totalProductsPrice",
      coupon: "coupon",
      couponDiscount: "couponDiscount",
      deliveryPrice: "deliveryPrice",
      totalPrice: "totalPrice",
      maxProcessingTime: "maxProcessingTime",
    }),
    totalProductsPriceLabel(): string {
      return formatPrice(this.totalProductsPrice);
    },
  },

  methods: {
    getErrorMessage,
    formatPrice,
    ...mapActions(ModuleNames.cart, ["createNewOrder"]),
    ...mapMutations(["setIsLoading"]),
    ...mapMutations(ModuleNames.cart, [
      "setDelivery",
      "setDeliveryPoint",
      "updateRecipient",
      "updateInvoiceData",
    ]),
    changeInvoiceData(user: User): void {
      this.isEditingInvoiceData = false;
      this.updateInvoiceData(user);
    },
    changeRecipient(user: User): void {
      this.isEditingRecipient = false;
      this.updateRecipient(user);
    },
    async goToPayment(): Promise<void> {
      if (
        (this.$refs.userForm as unknown as typeof ClarityUserForm)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          ?.isInvalidForm()
      ) {
        this.isEditingRecipient = true;
        this.$noty.error(this.$t("formContainsErrors") as string);
        return;
      }

      if (
        (this.$refs.invoiceDataForm as unknown as typeof ClarityUserForm)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          ?.isInvalidForm()
      ) {
        this.isEditingInvoiceData = true;
        this.$noty.error(this.$t("formContainsErrors") as string);
        return;
      }
      if (
        this.delivery === "paczkomat" &&
        !this.deliveryPoint?.foreign_access_point_id
      ) {
        this.$noty.error("Wybierz paczkomat");
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$noty.error(this.$t("formContainsErrors") as string);
        return;
      }
      try {
        this.setIsLoading(true);
        const res = await ShopService.createOrder({
          price: this.totalPrice,
          delivery: this.delivery,
          cart: this.cartPayload,
          coupon: this.coupon?._id,
          recipient: this.recipient,
          invoiceData: this.invoiceData,
          deliveryPoint:
            this.delivery === "paczkomat" ? this.deliveryPoint : undefined,
          acceptTermsAndConditions: this.acceptTermsAndConditions,
          maxProcessingTime: this.maxProcessingTime,
        });
        if (res?.payment.redirectUrl)
          window.location.href = res.payment.redirectUrl;
      } catch (e: any) {
        this.$noty.error("Error: " + e.message);
        this.setIsLoading(false);
      }
    },
    selectPaczkomat(): void {
      let payload = {};
      if (this.deliveryPoint) {
        payload = this.deliveryPoint;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.apaczkaMap?.show(payload);
    },
  },
  created(): void {},
  mounted(): void {
    const cookieInvoiceData = !VueCookies.get(
      "amshop-invoiceData-" + (this.authUser?.email ? this.authUser?.email : "")
    );
    const cookieRecipient = !VueCookies.get(
      "amshop-recipient-" + (this.authUser?.email ? this.authUser?.email : "")
    );
    if (!this.recipient && typeof cookieRecipient !== "object" && this.authUser)
      this.updateRecipient(this.authUser);
    if (
      !this.invoiceData &&
      typeof cookieInvoiceData !== "object" &&
      (this.authUser || this.recipient)
    )
      this.updateInvoiceData(this.authUser ? this.authUser : this.recipient);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    // eslint-disable-next-line no-undef
    this.apaczkaMap = new ApaczkaMap({
      app_id: process.env.VUE_APP_APACZKA_ID,
      onChange: (record: DeliveryPoint) => {
        if (record) {
          this.setDeliveryPoint(record);
        }
      },
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.apaczkaMap.setFilterSupplierAllowed(["INPOST"]);
  },
});
