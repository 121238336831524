
import { mapGetters, mapMutations, mapState } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import ClarityCartItem from "@/components/clarity/ClarityCartItem.vue";
import ClarityInput from "@/components/clarity/ClarityInput.vue";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import { formatPrice } from "@/helpers/shop.helper";
import { required } from "vuelidate/lib/validators";
import { getErrorMessage } from "@/helpers/validation";
import ShopService from "@/services/shop.service";
import ClarityOrderBreadcrumbs from "@/components/clarity/ClarityOrderBreadcrumbs.vue";
import UserService from "@/services/user.service";

export default Vue.extend({
  name: "FullCartView",
  components: {
    ClarityOrderBreadcrumbs,
    ClarityButton,
    ClarityInput,
    ClarityCartItem,
  },

  data: () => ({
    code: "",
    codeError: "",
    isCheckingCoupon: false,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, {
      isAuthenticated: "isAuthenticated",
      isApproved: "isApproved",
    }),
    ...mapState(ModuleNames.cart, {
      deliveryOptions: "deliveryOptions",
      delivery: "delivery",
    }),
    ...mapGetters(ModuleNames.cart, {
      cart: "cart",
      totalProductsPrice: "totalProductsPrice",
      coupon: "coupon",
      couponDiscount: "couponDiscount",
      deliveryPrice: "deliveryPrice",
      totalPrice: "totalPrice",
      maxProcessingTime: "maxProcessingTime",
    }),
    totalProductsPriceLabel(): string {
      return formatPrice(this.totalProductsPrice);
    },
  },
  validations: {
    code: {
      required,
    },
  },
  watch: {
    coupon(): void {
      this.codeError = "";
    },
  },

  methods: {
    formatPrice,
    ...mapMutations(ModuleNames.cart, [
      "addCoupon",
      "removeCoupon",
      "setDelivery",
    ]),
    ...mapMutations(["setIsLoading"]),

    async goToCheckout(): Promise<void> {
      if (this.isAuthenticated && !this.isApproved) {
        this.$noty.error(
          "Twoje konto nie zostało aktywowane. Postępuj zgodnie z instrukcją, którą otrzymasz na email."
        );
        try {
          this.setIsLoading(true);
          await UserService.sendAgainConfirmationEmail();
          this.$noty.success("Wysłano email. Sprawdź skrzynkę pocztową");
        } catch (e: any) {
          this.$noty.error("Error: " + e.message);
        } finally {
          this.setIsLoading(false);
        }
        return;
      }
      await this.$router.push("/dostawa-i-platnosc");
    },
    getErrorMessage,
    removeCodeAndCoupon(): void {
      this.$v.$reset();
      this.removeCoupon();
      this.code = "";
    },
    async isActiveCode(): Promise<void> {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$noty.error("Wprowadź kod rabatowy");
        return;
      }
      try {
        this.isCheckingCoupon = true;
        const res = await ShopService.couponIsActive(this.code);
        this.addCoupon(res.coupon);
      } catch (e: any) {
        this.codeError = "Kod rabatowy jest niepoprawny";
        this.$noty.error("Error: " + e.message);
      } finally {
        this.isCheckingCoupon = false;
      }
    },
  },
  mounted(): void {
    if (this.coupon) this.code = this.coupon.code;
  },
});
