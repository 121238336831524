
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import {
  formatPrice,
  getCanceledStatus,
  getPaymentConfirmedStatus,
  getPendingStatus,
} from "@/helpers/shop.helper";
import ClarityOrderBreadcrumbs from "@/components/clarity/ClarityOrderBreadcrumbs.vue";
import { Order } from "@/types/shop";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import ShopService from "@/services/shop.service";
import AccountMyShippingCartItem from "@/components/AccountMyShippingCartItem.vue";

export default Vue.extend({
  name: "OrderConfirmedView",
  components: {
    AccountMyShippingCartItem,
    ClarityButton,
    ClarityOrderBreadcrumbs,
    FontAwesomeIcon,
  },

  data: () => ({
    faCheckCircle,
    faTimesCircle,
    order: undefined as Order | undefined,
    isLoading: false,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, { isAuthenticated: "isAuthenticated" }),

    isConfirmedStatus(): boolean {
      if (!this.order) return false;
      return !!getPaymentConfirmedStatus(this.order?.statuses);
    },
    isCanceledStatus(): boolean {
      if (!this.order) return false;
      return !!getCanceledStatus(this.order?.statuses);
    },
    isPendingStatus(): boolean {
      if (!this.order) return false;

      return (
        !this.isConfirmedStatus && !!getPendingStatus(this.order?.statuses)
      );
    },
    totalProductsPriceLabel(): string {
      if (!this.order) return "";
      return formatPrice(this.order?.totalProductsPrice);
    },
    totalPriceLabel(): string {
      if (!this.order?.price) return "";
      return formatPrice(this.order?.price);
    },
  },
  methods: {
    formatPrice,
    ...mapMutations(ModuleNames.cart, ["clearCart"]),
    async tryAgain() {
      if (this.$route.query.orderNumber)
        try {
          const res = await ShopService.tryAgain(
            this.$route.query.orderNumber as string
          );
          if (res?.payment.redirectUrl)
            window.location.href = res.payment.redirectUrl;
        } catch (e: any) {
          console.log(e);
        }
    },
    async fetchCurrentOrder(): Promise<void> {
      if (this.$route.query.orderNumber) {
        const res = await ShopService.getOrder(
          this.$route.query.orderNumber.toString(),
          this.$route.query.email.toString()
        );
        this.order = res.order;
      }
      if (this.isPendingStatus) {
        setTimeout(() => this.fetchCurrentOrder(), 2000);
      }
    },
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;
      await this.fetchCurrentOrder();
    } catch (e: any) {
      if (e?.message !== "Order Not found.") {
        this.$noty.error("Error: " + e.message);
      }
    } finally {
      this.isLoading = false;
    }
  },
  watch: {
    "order.statuses.length"(): void {
      if (this.isConfirmedStatus) {
        this.clearCart();
      }
      if (this.isPendingStatus) {
        this.fetchCurrentOrder();
      }
    },
  },
});
