import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.scss";
import VueI18n from "vue-i18n";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { defaultLocale, messages } from "./i18n";
import Vuelidate from "vuelidate";
import VueTippy, { TippyComponent } from "vue-tippy";
// eslint-disable-next-line @typescript-eslint/no-var-requires
import VueMeta from "vue-meta";
import VueNoty from "vuejs-noty";
import "vuejs-noty/dist/vuejs-noty.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
import VModal from "vue-js-modal";
import VueLazyload from "vue-lazyload";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultimage = require("./assets/img/default-image.png");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const loadingimage = require("./assets/img/loading.svg");

Vue.use(VueLazyload, {
  error: defaultimage,
  loading: loadingimage,
  preLoad: 1.3,
});

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    height: "auto",
    classes: ["v--modal-box", "v--modal"],
    clickToClose: false,
  },
});
Vue.use(PrimeVue);

Vue.use(VueNoty, {
  theme: "metroui",
  layout: "bottomRight",
  closeWith: ["button"],
});
Vue.use(VueMeta);

Vue.use(VueI18n);
Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.use(VueTippy, {
  directive: "tippy",
  arrow: true,
  arrowType: "round",
  onShow: (options: any) => {
    return !!options.props.content;
  },
});
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("tippy", TippyComponent);
const i18n = new VueI18n({
  locale: defaultLocale,
  messages,
});
Vue.directive("click-outside", {
  bind(el: any, binding: any, vnode: any) {
    el.clickOutsideEvent = function (event: Event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el: any): void {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export { i18n };
