
import Vue, { PropType } from "vue";
import { Order } from "@/types/shop";
import { getDeliveredStatus } from "@/helpers/shop.helper";
import OrderStatus from "@/components/OrderStatus.vue";

export default Vue.extend({
  name: "OrderStatuses",
  components: {
    OrderStatus,
  },
  computed: {
    filteredStatuses(): Order["statuses"] {
      if (this.mode === "full") return this.visibleStatuses;
      let status = getDeliveredStatus(this.visibleStatuses);
      if (status !== undefined) return [status];
      return [this.visibleStatuses[this.visibleStatuses.length - 1]];
    },
    visibleStatuses(): Order["statuses"] {
      return this.statuses
        ?.filter((s) => !s.hidden)
        .sort((a, b) => {
          return a.createdAt > b.createdAt ? 1 : -1;
        });
    },
  },
  data: function () {
    return {
      shipmentNumber: "",
      isEditingShipmentNumber: true,
      isAddingShipmentNumber: false,
    };
  },
  props: {
    statuses: { type: Array as PropType<Order["statuses"]> },
    mode: { type: String, default: "" },
  },
  methods: {},
});
