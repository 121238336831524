
import Vue, { PropType } from "vue";
import ClarityInput from "@/components/clarity/ClarityInput.vue";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import { email, helpers, minLength, required } from "vuelidate/lib/validators";
import { getErrorMessage } from "@/helpers/validation";
import { User } from "@/types/user";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const isValidPhone = helpers.regex(
  "phone",
  /^(((00|\+)48)?[- ]?([0-9]{2}|\([0-9]{2}\))[- ]?[0-9]{3}[- ]?[0-9]{2}[- ]?[0-9]{2}|((00|\+)48)?[- ]?[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{3})$/
);
const isValidPostalCode = helpers.regex("postCode", /^[0-9]{2}[- ]?[0-9]{3}$/);

const isValidNip = (value: string) => {
  if (!value) return true;
  const nipWithoutDashesAndSpaces = value.replace(/-/g, "").replace(/ /g, "");
  const reg = /^[0-9]{10}$/;
  if (!helpers.req(value) || !reg.test(nipWithoutDashesAndSpaces)) {
    return false;
  }
  const digits = `${nipWithoutDashesAndSpaces}`.split("");
  const checksum =
    (6 * parseInt(digits[0]) +
      5 * parseInt(digits[1]) +
      7 * parseInt(digits[2]) +
      2 * parseInt(digits[3]) +
      3 * parseInt(digits[4]) +
      4 * parseInt(digits[5]) +
      5 * parseInt(digits[6]) +
      6 * parseInt(digits[7]) +
      7 * parseInt(digits[8])) %
    11;

  return parseInt(digits[9]) == checksum;
};

export default Vue.extend({
  name: "ClarityUserForm",
  computed: {},
  components: {
    ClarityButton,
    ClarityInput,
    FontAwesomeIcon,
  },
  props: {
    title: { type: String, default: "" },
    isLoading: { type: Boolean },
    isEditing: { type: Boolean },
    user: { type: Object as PropType<User> | undefined },
    hiddenEmail: { type: Boolean, default: false },
    disabledEmail: { type: Boolean, default: false },
    hiddenPhone: { type: Boolean, default: false },
    hiddenNip: { type: Boolean, default: true },
  },
  validations: {
    displayName: {
      required,
      minLength: minLength(3),
    },
    email: {
      required: (val, form) => {
        if (form.hiddenEmail) return true;
        return val;
      },
      email,
    },
    nip: { isValidNip },

    phone: {
      required: (val, form) => {
        if (form.hiddenPhone) return true;
        return val;
      },
      isValidPhone,
    },
    postCode: {
      required,
      isValidPostalCode,
    },
    city: {
      required,
    },
    street: {
      required,
    },
  },
  data: () => ({
    faEdit,
    displayName: "",
    nip: "",
    email: "",
    phone: "",
    postCode: "",
    city: "",
    street: "",
    dontChangeData: true,
  }),
  methods: {
    getErrorMessage,
    isInvalidForm(): boolean {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    async submit(): Promise<void> {
      if (this.isInvalidForm()) {
        this.$noty.error(this.$t("formContainsErrors") as string);
        return;
      }
      this.$v.$reset();
      this.$emit("submit", {
        _id: this.user?._id,
        displayName: this.displayName,
        email: this.email,
        phone: this.phone,
        approved: this.user?.approved,
        postCode: this.postCode,
        street: this.street,
        city: this.city,
        nip: this.nip,
      });
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(): void {
        this.displayName = this.user?.displayName ? this.user?.displayName : "";
        this.nip = this.user?.nip ? this.user?.nip : "";
        this.email = this.user?.email ? this.user?.email : "";
        this.phone = this.user?.phone ? this.user?.phone : "";
        this.postCode = this.user?.postCode ? this.user?.postCode : "";
        this.street = this.user?.street ? this.user?.street : "";
        this.city = this.user?.city ? this.user?.city : "";
      },
    },
  },
});
