
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { ModuleNames } from "@/store";
import { convertStringToUrl } from "@/helpers/url";

export default defineComponent({
  name: "BlogsView",
  methods: { convertStringToUrl },
  computed: {
    ...mapGetters(ModuleNames.blog, {
      blogs: "blogs",
      blogsIsLoading: "blogsIsLoading",
    }),
  },
});
