import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import VueGtag from "vue-gtag";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import store, { initAuth, ModuleNames } from "@/store";
import { TokenService } from "@/services/token.service";
const AccountView = () => import("@/views/account/AccountView.vue");
import RegisterView from "@/views/RegisterView.vue";
import { UserRole } from "@/types/user";
const AccountSettingsView = () =>
  import("@/views/account/AccountSettingsView.vue");
const AccountShopView = () =>
  import("@/views/account/shop/AccountShopView.vue");
const AccountShopCategoriesView = () =>
  import("@/views/account/shop/AccountShopCategoriesView.vue");
const AccountShopFieldsView = () =>
  import("@/views/account/shop/fields/AccountShopFieldsView.vue");
const AccountShopProductsView = () =>
  import("@/views/account/shop/products/AccountShopProductsView.vue");
import ProductView from "@/views/ProductView.vue";
import ProductsView from "@/views/ProductsView.vue";
const AccountShopFieldsAddView = () =>
  import("@/views/account/shop/fields/AccountShopFieldsAddView.vue");
const AccountShopFieldsEditView = () =>
  import("@/views/account/shop/fields/AccountShopFieldsEditView.vue");
const AccountShopProductsAddView = () =>
  import("@/views/account/shop/products/AccountShopProductsAddView.vue");
const AccountShopProductsEditView = () =>
  import("@/views/account/shop/products/AccountShopProductsEditView.vue");
import FullCartView from "@/views/FullCartView.vue";
const AccountShopCouponsView = () =>
  import("@/views/account/shop/coupons/AccountShopCouponsView.vue");
const AccountShopCouponsAddView = () =>
  import("@/views/account/shop/coupons/AccountShopCouponsAddView.vue");
const AccountShopCouponsEditView = () =>
  import("@/views/account/shop/coupons/AccountShopCouponsEditView.vue");
import CheckoutView from "@/views/CheckoutView.vue";
import OrderConfirmedView from "@/views/OrderConfirmedView.vue";
import TokenErrorView from "@/views/TokenErrorView.vue";
import TokenSuccessView from "@/views/TokenSuccessView.vue";
const AccountMyShippingsView = () =>
  import("@/views/account/AccountMyShippingsView.vue");
const AccountMyShippingView = () =>
  import("@/views/account/AccountMyShippingView.vue");
const AccountOrdersView = () =>
  import("@/views/account/shop/orders/AccountOrdersView.vue");
const AccountOrderView = () =>
  import("@/views/account/shop/orders/AccountOrderView.vue");
import OrderView from "@/views/OrderView.vue";
const AccountShopHeadersView = () =>
  import("@/views/account/shop/headers/AccountShopHeadersView.vue");
const AccountShopHeadersAddView = () =>
  import("@/views/account/shop/headers/AccountShopHeadersAddView.vue");
const AccountShopHeadersEditView = () =>
  import("@/views/account/shop/headers/AccountShopHeadersEditView.vue");
const AccountShopBlogsView = () =>
  import("@/views/account/shop/blogs/AccountShopBlogsView.vue");
const AccountShopBlogsAddView = () =>
  import("@/views/account/shop/blogs/AccountShopBlogsAddView.vue");
const AccountShopBlogsEditView = () =>
  import("@/views/account/shop/blogs/AccountShopBlogsEditView.vue");
import BlogsView from "@/views/BlogsView.vue";
import BlogView from "@/views/BlogView.vue";
const AccountShopPagesEditView = () =>
  import("@/views/account/shop/pages/AccountShopPagesEditView.vue");
const AccountShopPagesAddView = () =>
  import("@/views/account/shop/pages/AccountShopPagesAddView.vue");
const AccountShopPagesView = () =>
  import("@/views/account/shop/pages/AccountShopPagesView.vue");
import PageView from "@/views/PageView.vue";
import NewPasswordView from "@/views/NewPasswordView.vue";
import ContactView from "@/views/ContactView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/token-error",
    name: "token-error",
    component: TokenErrorView,
    meta: {
      name: "Nieprawidłowy token",
    },
  },
  {
    path: "/token-success",
    name: "token-success",
    component: TokenSuccessView,
    meta: {
      name: "Email został potwierdzony",
    },
  },
  {
    path: "/logowanie",
    name: "login",
    component: LoginView,
    meta: {
      isGuest: true,
      name: "Logowanie",
    },
  },
  {
    path: "/zresetuj-haslo",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      isGuest: true,
      name: "Zresetuj hasło",
    },
  },
  {
    path: "/ustaw-nowe-haslo",
    name: "new-password",
    component: NewPasswordView,
    meta: {
      name: "Ustaw nowe hasło",
    },
  },
  {
    path: "/blog",
    name: "blogs",
    component: BlogsView,
    meta: {
      name: "Blog",
    },
  },
  {
    path: "/blog/:id",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/rejestracja",
    name: "register",
    component: RegisterView,
    meta: {
      isGuest: true,
      name: "Rejestracja",
    },
  },
  {
    path: "/konto/ustawienia",
    name: "account-settings",
    component: AccountSettingsView,
    meta: {
      isAuthenticated: true,
      name: "Ustawienia",
    },
  },
  {
    path: "/konto/zakupy",
    name: "account-my-shippings",
    component: AccountMyShippingsView,
    meta: {
      isAuthenticated: true,
      name: "Moje zakupy",
    },
  },
  {
    path: "/konto/zakupy/:id",
    name: "account-my-shipping",
    component: AccountMyShippingView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/konto/sklep",
    name: "account-shop",
    component: AccountShopView,
    meta: {
      isAuthenticated: true,
      role: [UserRole.SHOP_MODERATOR],
      name: "Sklep",
    },
    children: [
      {
        path: "/konto/sklep/strony",
        name: "account-shop-pages",
        component: AccountShopPagesView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Strony",
        },
      },
      {
        path: "/konto/sklep/strony/dodaj",
        name: "account-shop-pages-add",
        component: AccountShopPagesAddView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Dodaj stronę",
        },
      },
      {
        path: "/konto/sklep/strony/edytuj/:id",
        name: "account-shop-pages-edit",
        component: AccountShopPagesEditView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Edytuj stronę",
        },
      },
      {
        path: "/konto/sklep/blog",
        name: "account-shop-blogs",
        component: AccountShopBlogsView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Artykuły",
        },
      },
      {
        path: "/konto/sklep/blog/dodaj",
        name: "account-shop-blogs-add",
        component: AccountShopBlogsAddView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Dodaj artykuł",
        },
      },
      {
        path: "/konto/sklep/blog/edytuj/:id",
        name: "account-shop-blogs-edit",
        component: AccountShopBlogsEditView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Edytuj artykuł",
        },
      },
      {
        path: "/konto/sklep/naglowek",
        name: "account-shop-headers",
        component: AccountShopHeadersView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Slajdy",
        },
      },
      {
        path: "/konto/sklep/naglowek/dodaj",
        name: "account-shop-headers-add",
        component: AccountShopHeadersAddView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Dodaj slajd",
        },
      },
      {
        path: "/konto/sklep/naglowek/edytuj/:id",
        name: "account-shop-headers-edit",
        component: AccountShopHeadersEditView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Edytuj slajd",
        },
      },
      {
        path: "/konto/sklep/kategorie",
        name: "account-shop-categories",
        component: AccountShopCategoriesView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Kategorie",
        },
      },
      {
        path: "/konto/sklep/pola",
        name: "account-shop-fields",
        component: AccountShopFieldsView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Pola",
        },
      },
      {
        path: "/konto/sklep/pola/dodaj",
        name: "account-shop-fields-add",
        component: AccountShopFieldsAddView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Dodaj pole",
        },
      },
      {
        path: "/konto/sklep/pola/edytuj/:id",
        name: "account-shop-fields-edit",
        component: AccountShopFieldsEditView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Edytuj pole",
        },
      },
      {
        path: "/konto/sklep/produkty",
        name: "account-shop-products",
        component: AccountShopProductsView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Produkty",
        },
      },
      {
        path: "/konto/sklep/produkty/dodaj",
        name: "account-shop-products-add",
        component: AccountShopProductsAddView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Dodaj produkt",
        },
      },
      {
        path: "/konto/sklep/produkty/edytuj/:id",
        name: "account-shop-products-edit",
        component: AccountShopProductsEditView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Edytuj produkt",
        },
      },
      {
        path: "/konto/sklep/kody",
        name: "account-shop-coupons",
        component: AccountShopCouponsView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Kupony",
        },
      },
      {
        path: "/konto/sklep/kody/dodaj",
        name: "account-shop-coupons-add",
        component: AccountShopCouponsAddView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Dodaj kupon",
        },
      },
      {
        path: "/konto/sklep/kody/edytuj/:id",
        name: "account-shop-coupons-edit",
        component: AccountShopCouponsEditView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Edytuj kupon",
        },
      },
      {
        path: "/konto/sklep/zamowienia",
        name: "account-shop-orders",
        component: AccountOrdersView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Zamówienia",
        },
      },
      {
        path: "/konto/sklep/zamowienia/:id",
        name: "account-shop-order",
        component: AccountOrderView,
        meta: {
          isAuthenticated: true,
          role: [UserRole.SHOP_MODERATOR],
          name: "Szczegóły zamówienia",
        },
      },
    ],
  },
  {
    path: "/produkty",
    name: "products",
    component: ProductsView,
    meta: {
      name: "Produkty",
    },
  },
  {
    path: "/produkt/:id",
    name: "product",
    component: ProductView,
  },
  {
    path: "/konto",
    name: "account",
    component: AccountView,
    meta: {
      isAuthenticated: true,
      name: "Konto",
    },
  },
  // {
  //   path: "/users",
  //   name: "users",
  //   component: UsersView,
  //   meta: {
  //     isAuthenticated: true,
  //     isModerator: true,
  //   },
  // },
  {
    path: "/koszyk",
    name: "cart",
    component: FullCartView,
    meta: {
      name: "Koszyk",
    },
  },
  {
    path: "/kontakt",
    name: "contact",
    component: ContactView,
    meta: {
      name: "Kontakt",
    },
  },
  {
    path: "/dostawa-i-platnosc",
    name: "checkout",
    component: CheckoutView,
    meta: {
      name: "Dostawa i płatność",
    },
  },
  {
    path: "/gotowe",
    name: "order-confirmed",
    component: OrderConfirmedView,
    meta: {
      name: "Zamówenie złożone",
    },
  },
  {
    path: "/zamowienie",
    name: "order-view",
    component: OrderView,
    meta: {
      name: "Zamówienie",
    },
  },
  {
    path: "/:link",
    name: "page-view",
    component: PageView,
  },
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        top: document.querySelector(to.hash)?.offsetTop,
        behavior: "smooth",
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setIsLoading", true);
  let isAuthenticated = store.getters[`${ModuleNames.auth}/isAuthenticated`];
  let roles = store.getters[`${ModuleNames.auth}/authUser`]?.roles || [];
  const accessToken = TokenService.getToken();
  if (!isAuthenticated && accessToken) {
    await initAuth();
    isAuthenticated = store.getters[`${ModuleNames.auth}/isAuthenticated`];
    roles = store.getters[`${ModuleNames.auth}/authUser`]?.roles || [];
  }
  if (!isAuthenticated && to.path !== "/logowanie") {
    store.commit("setLastVisitedRoute", to.fullPath);
  }

  if (to.meta?.isGuest && isAuthenticated) {
    next({ name: "account" });
  }
  if (to.meta?.isAuthenticated && !isAuthenticated) {
    next({ name: "login" });
  }
  if (to.meta?.roles?.length && !roles?.includes(to.meta?.roles)) {
    next({ name: "login" });
  }
  next();

  setTimeout(() => {
    store.commit("setIsLoading", false);
  }, 300);
});
Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GA_KEY,
      params: {
        send_page_view: false,
      },
    },
    includes: [{ id: process.env.VUE_APP_GA_KEY2 }],
  },
  router
);

export default router;
