
import Vue, { PropType } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faShoppingCart,
  faCreditCard,
  faCheckCircle,
  faShippingFast,
  faBoxOpen,
  faQrcode,
  faTruck,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

export default Vue.extend({
  name: "OrderStatus",
  components: {
    FontAwesomeIcon,
  },
  computed: {},
  data: function () {
    return {
      faBan,
      faTruck,
      faShoppingCart,
      faCreditCard,
      faCheckCircle,
      faShippingFast,
      faBoxOpen,
      faQrcode,
      shipmentNumber: "",
      isEditingShipmentNumber: true,
      isAddingShipmentNumber: false,
    };
  },
  props: {
    status: {
      type: Object as PropType<{
        createdAt: string;
        status: string;
        data: any;
      }>,
    },
    mode: { type: String, default: "" },
  },
  methods: {
    getStatusLabel(status: {
      createdAt: string;
      status: string;
      data: any;
    }): string {
      if (status.status.startsWith("INPOST")) return status.data.title;
      return this.$t("orderStatus." + status.status) as string;
    },
    getStatusColor(statusKey: string): string {
      if (statusKey.includes("ERROR") || statusKey.includes("CANCELED"))
        return "text-red-500";
      if (
        statusKey === "PARCEL_RECEIVED" ||
        statusKey === "PARCEL_DELIVERED" ||
        statusKey === "INPOST_delivered"
      )
        return "text-green-600";
      if (statusKey === "NEW") return "text-gray-500";
      return "text-indigo-600";
    },
  },
});
