import { axiosInstance } from "@/helpers/auth";
import { GalleryImage } from "@/types/main";

const GalleryService = {
  getGallery: async function (): Promise<{
    images: Array<GalleryImage>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/gallery`,
    });
  },
  uploadFile: async function (payload: FormData) {
    return await axiosInstance({
      method: "post",
      url: `/api/gallery/upload`,
      data: payload,
    });
  },
  deleteImage: async function (payload: GalleryImage) {
    return await axiosInstance({
      method: "delete",
      url: `/api/gallery`,
      data: payload,
    });
  },
};
export default GalleryService;
