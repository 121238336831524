
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import AccountShopOrder from "@/components/AccountShopOrder.vue";
import { Order } from "@/types/shop";

import ShopService from "@/services/shop.service";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default Vue.extend({
  name: "OrderView",
  components: { AccountShopOrder, FontAwesomeIcon },
  data: () => ({
    order: undefined as Order | undefined,
    isLoading: false,
    faCheckCircle,
    faTimesCircle,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, { isAuthenticated: "isAuthenticated" }),
  },
  methods: {
    async fetchCurrentOrder(): Promise<void> {
      if (this.$route.query.orderNumber) {
        const res = await ShopService.getOrder(
          this.$route.query.orderNumber.toString(),
          this.$route.query.email.toString()
        );
        this.order = res.order;
      }
    },
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;
      await this.fetchCurrentOrder();
    } catch (e: any) {
      if (e?.message !== "Order Not found.") {
        this.$noty.error("Error: " + e.message);
      }
    } finally {
      this.isLoading = false;
    }
  },
});
