var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"flex mb-6"},[_c('ol',{staticClass:"flex flex-wrap gap-y-5 md:gap-y-0 items-center gap-x-1.5",attrs:{"role":"list"}},[_c('li',[_c('div',{staticClass:"-m-1"},[_c(!_vm.cart.length ? 'span' : 'router-link',{tag:"component",staticClass:"p-1 ml-1.5 text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900",class:{
            'text-gray-900': _vm.$route.path === '/koszyk',
            'hover:text-gray-700': _vm.cart.length,
          },attrs:{"to":"/koszyk","title":""}},[_vm._v(" Twój koszyk ")])],1)]),_c('li',[_c('div',{staticClass:"flex items-center"},[_c('svg',{staticClass:"flex-shrink-0 w-4 h-4 text-gray-400",attrs:{"fill":"none","xmlns":"http://www.w3.org/2000/svg","stroke":"currentColor","viewBox":"0 0 20 20","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})]),_c('div',{staticClass:"-m-1"},[_c(!_vm.cart.length || (_vm.isAuthenticated && !_vm.isApproved)
                ? 'span'
                : 'router-link',{tag:"component",staticClass:"p-1 ml-1.5 text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900",class:{
              'text-gray-900': _vm.$route.path === '/dostawa-i-platnosc',
              'hover:text-gray-700':
                _vm.cart.length && (_vm.isAuthenticated ? _vm.isApproved : true),
            },attrs:{"to":"/dostawa-i-platnosc","title":""}},[_vm._v(" Dostawa i płatność ")])],1)])]),_c('li',[_c('div',{staticClass:"flex items-center"},[_c('svg',{staticClass:"flex-shrink-0 w-4 h-4 text-gray-400",attrs:{"fill":"none","xmlns":"http://www.w3.org/2000/svg","stroke":"currentColor","viewBox":"0 0 20 20","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})]),_c('div',{staticClass:"-m-1"},[_c('span',{staticClass:"p-1 ml-1.5 text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900",class:{
              'text-gray-900': _vm.$route.path === '/gotowe',
            },attrs:{"title":""}},[_vm._v(" Gotowe ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }