
import Vue from "vue";

export default Vue.extend({
  name: "ClarityTextArea",
  components: {},
  props: {
    size: { type: String, default: "md" },
    rows: { type: Number, default: 3 },
    value: { type: undefined, default: "" },
    label: { type: undefined, default: "" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  data: (): { model: any } => ({
    model: null,
  }),
  watch: {
    model(): void {
      if (this.model !== null && this.model !== undefined) {
        if (this.model === "") this.$emit("input", undefined);
        else this.$emit("input", this.model);
      }
    },

    value: {
      immediate: true,
      handler(): void {
        this.model = this.value;
      },
    },
  },

  methods: {
    onBlur(): void {
      if (this.model !== null && this.model !== undefined) {
        this.$emit("blur", this.model);
      }
    },
  },
});
