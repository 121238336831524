import type { Commit, Dispatch } from "vuex";
import { Blog, Header } from "@/types/main";

import BlogService from "@/services/blog.service";
import { Coupon } from "@/types/shop";
import ShopService from "@/services/shop.service";
import { ShopActions } from "@/store/shop";
import { RootActions } from "@/store/index";

export enum BlogActions {
  FETCH_BLOGS = "fetchBlogs",
  ADD_BLOG = "addBlog",
  UPDATE_BLOG = "updateBlog",
  DELETE_BLOG = "deleteBlog",
}

export enum BlogMutations {
  SET_BLOGS = "setBlogs",
  SET_BLOGS_IS_LOADING = "setBlogsIsLoading",
}

export interface BlogState {
  blogs: Array<Blog>;
  blogsIsLoading: boolean;
}

export const state = (): BlogState => ({
  blogs: [],
  blogsIsLoading: false,
});

export const getters = {
  blogs: (state: BlogState) => state.blogs,
  blogsIsLoading: (state: BlogState) => state.blogsIsLoading,
};

export const actions = {
  async [BlogActions.FETCH_BLOGS]({
    commit,
  }: {
    commit: Commit;
  }): Promise<void> {
    try {
      commit(BlogMutations.SET_BLOGS_IS_LOADING, true);
      const response = await BlogService.getBlogs();
      commit(BlogMutations.SET_BLOGS, response.blogs);
    } finally {
      commit(BlogMutations.SET_BLOGS_IS_LOADING, false);
    }
  },
  async [BlogActions.ADD_BLOG](
    { dispatch }: { dispatch: Dispatch },
    payload: Blog
  ) {
    await BlogService.addBlog(payload);
    await dispatch(BlogActions.FETCH_BLOGS);
  },
  async [BlogActions.UPDATE_BLOG](
    { dispatch }: { dispatch: Dispatch },
    payload: Blog
  ) {
    await BlogService.updateBlog(payload);
    await dispatch(BlogActions.FETCH_BLOGS);
  },
  async [BlogActions.DELETE_BLOG](
    { dispatch }: { dispatch: Dispatch },
    payload: Blog
  ) {
    await BlogService.deleteBlog(payload);
    await dispatch(BlogActions.FETCH_BLOGS);
  },
};

export const mutations = {
  [BlogMutations.SET_BLOGS](state: BlogState, payload: Array<Blog>) {
    state.blogs = payload;
  },
  [BlogMutations.SET_BLOGS_IS_LOADING](state: BlogState, payload: boolean) {
    state.blogsIsLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
