var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.product)?_c('div',{staticClass:"relative group",class:{ 'opacity-40': _vm.product.thumbnail.totalQuantity === 0 }},[_c('div',{staticClass:"overflow-hidden h-50 w-full"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
        _vm.product.images?.length
          ? _vm.product.images[0]?.isNew
            ? _vm.product.images[0]?.thumb_url
            : _vm.product.images[0]?.url
          : '/images/default-image.png'
      ),expression:"\n        product.images?.length\n          ? product.images[0]?.isNew\n            ? product.images[0]?.thumb_url\n            : product.images[0]?.url\n          : '/images/default-image.png'\n      "}],staticClass:"object-cover h-full w-full transition-all duration-200 group-hover:scale-125",attrs:{"alt":(_vm.product?.title || 'Produkt') + ' - zdjęcie'}})]),_c('div',{staticClass:"absolute left-2 top-2 flex gap-1 max-w-full flex-wrap"},[(_vm.discount)?_c('p',{staticClass:"mb-0 px-2 py-1 text-xs font-bold tracking-wide text-white uppercase bg-red-400 rounded-full"},[_vm._v(" "+_vm._s(_vm.discount)+" ")]):_vm._e(),(_vm.product.isNew)?_c('p',{staticClass:"mb-0 bg-gray-100 px-2 py-1 text-xs font-bold tracking-wide text-gray-900 uppercase rounded-full"},[_vm._v(" Nowość ")]):_vm._e(),(_vm.product.isSale)?_c('p',{staticClass:"mb-0 px-2 py-1 text-xs font-bold tracking-wide text-white uppercase bg-gray-900 rounded-full"},[_vm._v(" Wyprzedaż ")]):_vm._e(),(_vm.product.isPremium)?_c('p',{staticClass:"mb-0 px-2 py-1 text-xs font-bold tracking-wide text-white uppercase bg-indigo-600 rounded-full"},[_vm._v(" Premium ")]):_vm._e()]),_c('div',{staticClass:"flex flex-col items-start justify-between mt-4"},[_c('div',{staticClass:"h-14"},[_c('h3',{staticClass:"text-xs font-bold text-gray-900 sm:text-sm md:text-base line-clamp-2"},[_c('router-link',{staticClass:"product-link",attrs:{"to":'/produkt/' +
            _vm.product._id +
            '-' +
            _vm.convertStringToUrl(_vm.product.title) +
            (_vm.product.categories &&
            _vm.product.categories.length > 1 &&
            _vm.categoryId &&
            _vm.categoryId !== _vm.product.categories[0]
              ? '?kategoria=' + _vm.categoryId
              : '')}},[_vm._v(" "+_vm._s(_vm.product.title)+" "),_c('span',{staticClass:"absolute inset-0",attrs:{"aria-hidden":"true"}})])],1)]),_c('p',{staticClass:"text-xs mb-1 text-gray-500 font-normal text-right w-full"},[_vm._v(" najniższa cena z 30 dni: "+_vm._s(_vm.minPrice)+" ")]),_c('div',{staticClass:"flex justify-between w-full"},[(_vm.product.thumbnail.totalQuantity !== 0)?_c('div',[(!_vm.product.variants || !_vm.product.variants?.length)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: 'Dodaj do koszyka' }),expression:"{ content: 'Dodaj do koszyka' }"}],staticClass:"relative inline-flex items-center justify-center text-white transition-all duration-200 bg-gray-900 rounded-full w-8 h-8 hover:bg-gray-700",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.addProductToCart.apply(null, arguments)}}},[_c('SvgIcon',{staticClass:"w-4 h-4",attrs:{"icon":"cart"}})],1):_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: 'Skonfiguruj swój produkt' }),expression:"{ content: 'Skonfiguruj swój produkt' }"}],staticClass:"relative inline-flex items-center justify-center text-white transition-all duration-200 bg-gray-900 rounded-full w-8 h-8 hover:bg-gray-700",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();_vm.$router.push(
              '/produkt/' +
                _vm.product._id +
                '-' +
                _vm.convertStringToUrl(_vm.product.title) +
                (_vm.product.categories &&
                _vm.product.categories.length > 1 &&
                _vm.categoryId &&
                _vm.categoryId !== _vm.product.categories[0]
                  ? '?kategoria=' + _vm.categoryId
                  : '')
            )}}},[_c('FontAwesomeIcon',{staticClass:"w-4 h-4",attrs:{"icon":_vm.faSlidersH}})],1)]):_vm._e(),_c('div',{staticClass:"text-right w-full"},[_c('p',{staticClass:"text-xs -mb-2 font-bold text-gray-900 sm:text-sm md:text-base"},[_vm._v(" "+_vm._s(_vm.product.variants && _vm.product.variants?.length ? "od " : "")+" "+_vm._s(_vm.priceLabel)+" ")]),_c('del',{directives:[{name:"show",rawName:"v-show",value:(_vm.price !== _vm.standardPrice),expression:"price !== standardPrice"}],staticClass:"mt-0.5 text-xs font-bold text-gray-500"},[_vm._v(" "+_vm._s(_vm.standardPriceLabel)+" ")])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }