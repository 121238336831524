var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'mt-1 flex flex-col select',
    { 'select--error': _vm.error },
    { 'select--disabled': _vm.disabled },
    'select--' + _vm.size,
  ]},[(_vm.label)?_c('label',{staticClass:"text-base font-medium text-gray-900 font-pj w-full",attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('Dropdown',{class:[{ 'mt-2.5': _vm.label }],attrs:{"disabled":_vm.disabled,"value":_vm.value,"options":_vm.items,"filter":_vm.filter,"option-label":"label","option-value":"value","placeholder":_vm.placeholder},on:{"input":function($event){return _vm.$emit('input', $event)}}}),(_vm.error)?_c('p',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
      'text-xs text-red-500 truncate max-w-full -bottom-6 mt-1 ml-3 text-left',
    ],attrs:{"content":_vm.error}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }