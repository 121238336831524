
import SvgIcon from "@/components/SvgIcon.vue";
import Vue from "vue";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters } from "vuex";
import { ModuleNames } from "@/store";
import { Page } from "@/types/main";

export default Vue.extend({
  name: "ClarityFooter",
  components: { SvgIcon, FontAwesomeIcon },
  data: (): {
    faFacebookF: any;
    faInstagram: any;
    faTiktok: any;
  } => ({
    faFacebookF,
    faInstagram,
    faTiktok,
  }),
  computed: {
    ...mapGetters(ModuleNames.page, { pages: "pages" }),
    aboutAsPage(): Page | undefined {
      return this.pages?.find((p: Page) => p.link === "/o-nas");
    },
    termPage(): Page | undefined {
      return this.pages?.find((p: Page) => p.link === "/regulamin");
    },
    deliveryPage(): Page | undefined {
      return this.pages?.find((p: Page) => p.link === "/wysylka-i-platnosci");
    },
    privacyPolicyPage(): Page | undefined {
      return this.pages?.find((p: Page) => p.link === "/polityka-prywatnosci");
    },
  },
});
