
import Vue from "vue";
import type { PropType } from "vue";
import { ShopProduct } from "@/types/shop";
import { convertStringToUrl } from "@/helpers/url";
import { formatPrice } from "@/helpers/shop.helper";
import SvgIcon from "@/components/SvgIcon.vue";
import { mapActions } from "vuex";
import { ModuleNames } from "@/store";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default Vue.extend({
  name: "ProductThumbnail",
  props: {
    product: { type: Object as PropType<ShopProduct> },
    categoryId: { type: String, default: "" },
  },
  components: { SvgIcon, FontAwesomeIcon },
  data: function () {
    return {
      faSlidersH,
    };
  },
  computed: {
    discount(): string {
      if (this.product?.thumbnail.discount) {
        return "-" + this.product?.thumbnail.discount + "%";
      }
      return "";
    },
    price(): number | undefined {
      return this.product?.thumbnail.price;
    },
    priceLabel(): string | undefined {
      if (!this.price) return undefined;
      return formatPrice(this.price);
    },
    standardPrice(): number | undefined {
      return this.product?.thumbnail.standardPrice;
    },
    standardPriceLabel(): string | undefined {
      if (!this.standardPrice) return undefined;
      return formatPrice(this.standardPrice);
    },
    minPrice(): string {
      const minPrice = this.product?.thumbnail.minPrice;
      if (minPrice) return formatPrice(minPrice);
      return "-";
    },
  },
  methods: {
    ...mapActions(ModuleNames.cart, ["addToCart"]),
    convertStringToUrl,
    addProductToCart(): void {
      if (this.product.isHidden) {
        this.$noty.error(
          "Ten produkt jest niedostępny. Odśwież stronę i spróbuj ponownie"
        );
        return;
      }
      this.addToCart({
        product: { ...this.product },
        variantKeys: undefined,
        variant: undefined,
      });
    },
  },
});
