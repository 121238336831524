
import Vue from "vue";
import { mapGetters } from "vuex";
import { ModuleNames } from "@/store";

export default Vue.extend({
  name: "ClarityOrderBreadcrumbs",
  computed: {
    ...mapGetters(ModuleNames.cart, { cart: "cart" }),
    ...mapGetters(ModuleNames.auth, {
      isApproved: "isApproved",
      isAuthenticated: "isAuthenticated",
    }),
  },
});
