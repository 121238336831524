
import Vue from "vue";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default Vue.extend({
  name: "ClarityButton",
  methods: {
    faCheck() {
      return faCheck;
    },
  },
  components: { FontAwesomeIcon },
  props: {
    theme: { type: String, default: "primary" },
    fontColor: { type: String, default: "white" },
    text: { type: String, default: "OK" },
    disabled: { type: Boolean, default: false },
    success: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    size: { type: String, default: "md" },
  },
});
