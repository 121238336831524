import pl from "./pl-PL";
import en from "./en-US";
const defaultLocale = "pl";

const locales = [
  {
    code: "en",
    name: "English",
    file: en,
  },
  {
    code: "pl",
    name: "Polski",
    file: pl,
  },
];
const messages = {};
locales.forEach((loc) => (messages[loc.code] = loc.file));
export { defaultLocale, locales, messages };
