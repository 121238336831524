import { axiosInstance } from "@/helpers/auth";
import { Page } from "@/types/main";

const PageService = {
  getPages: async function (): Promise<{
    pages: Array<Page>;
  }> {
    return await axiosInstance({
      method: "get",
      url: `/api/pages`,
    });
  },
  addPage: async function (payload: Page): Promise<{
    category: Page;
  }> {
    return await axiosInstance({
      method: "post",
      url: `/api/pages`,
      data: payload,
    });
  },
  updatePage: async function (payload: Page): Promise<{
    category: Page;
  }> {
    return await axiosInstance({
      method: "put",
      url: `/api/pages/` + payload._id,
      data: payload,
    });
  },

  deletePage: async function (payload: Page): Promise<void> {
    return await axiosInstance({
      method: "delete",
      url: `/api/pages/` + payload._id,
    });
  },
};
export default PageService;
