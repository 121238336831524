
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Vue from "vue";
import { getErrorMessage } from "@/helpers/validation";
import { ModuleNames } from "@/store";
import ClarityInput from "@/components/clarity/ClarityInput.vue";
import ClarityButton from "@/components/clarity/ClarityButton.vue";

export default Vue.extend({
  name: "NewPasswordView",
  components: {
    ClarityButton,
    ClarityInput,
  },
  validations: {
    password: {
      required,
      minLength: minLength(7),
    },
    confirmPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  data: (): {
    password: string;
    confirmPassword: string;
    formError: string;
    isLoading: boolean;
  } => ({
    password: "",
    confirmPassword: "",
    formError: "",
    isLoading: false,
  }),
  methods: {
    ...mapActions(ModuleNames.auth, ["createNewPassword"]),
    getErrorMessage(validation: any): string {
      return getErrorMessage(validation);
    },
    async createUserPassword(): Promise<void> {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$noty.error(this.$t("formContainsErrors") as string);
        return;
      }
      this.isLoading = true;
      try {
        await this.createNewPassword({
          ...this.$route.query,
          password: this.password,
          confirmPassword: this.confirmPassword,
        });
        this.$noty.success("Zaktualizowano hasło.");
        this.password = "";
        this.confirmPassword = "";
        this.$router.push({ name: "login" });
      } catch (e: any) {
        this.$noty.error(this.$t("formContainsErrors") as string);
        this.formError = this.$t(e.message) as string;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
