import { User } from "@/types/user";

export enum SortType {
  ASC = "asc",
  DESC = "desc",
}

export interface Auth {
  loggedIn: boolean;
  user: User;
}

export interface Breadcrumb {
  link: string;
  label: string;
}

export interface Modal {
  visible: boolean;
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
  description?: string;
  title?: string;
  icon?: string;
  iconColor?: string;
  confirmButtonText?: string;
  loading?: boolean;
}

export enum ModalWidth {
  small = "360px",
  normal = "543px",
  middle = "660px",
  big = "720px",
  large = "1340px",
}
export interface GalleryImage {
  name?: string;
  url: string;
  thumb_url?: string;
  updatedAt: string;
  createdAt?: string;
  folder?: string;
  isNew?: boolean;
}
export interface Header {
  _id: string;
  link?: string;
  title?: string;
  description?: string;
  image?: GalleryImage;
  order: number;
}
export interface Blog {
  _id: string;
  title: string;
  content: string;
  image?: GalleryImage;
  tags?: Array<string>;
  createdAt?: string;
  hasSlides?: boolean;
  slides?: Array<string>;
  slidesTitle?: string;
  slidesDescription?: string;
}
export interface Page {
  _id: string;
  title: string;
  link: string;
  content: string;
  tags?: Array<string>;
  image?: GalleryImage;
  createdAt?: string;
  updatedAt?: string;
  protected: boolean;
}
