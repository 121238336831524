var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.label)?_c('label',{staticClass:"text-base font-medium text-gray-900 font-pj",attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{class:{ 'mt-2.5': _vm.label }},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"ml-0.4 block w-full text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900 disabled:opacity-60",class:[
        { 'border-red-500': _vm.error },
        { 'p-4': _vm.size === 'md' },
        { 'px-3 py-2': _vm.size === 'sm' },
        { 'px-3 py-1': _vm.size === 'xs' },
      ],attrs:{"min":_vm.min,"name":_vm.label,"step":_vm.step,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('submit', _vm.model)},"blur":_vm.onBlur,"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"ml-0.4 block w-full text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900 disabled:opacity-60",class:[
        { 'border-red-500': _vm.error },
        { 'p-4': _vm.size === 'md' },
        { 'px-3 py-2': _vm.size === 'sm' },
        { 'px-3 py-1': _vm.size === 'xs' },
      ],attrs:{"min":_vm.min,"name":_vm.label,"step":_vm.step,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('submit', _vm.model)},"blur":_vm.onBlur,"change":function($event){_vm.model=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"ml-0.4 block w-full text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900 disabled:opacity-60",class:[
        { 'border-red-500': _vm.error },
        { 'p-4': _vm.size === 'md' },
        { 'px-3 py-2': _vm.size === 'sm' },
        { 'px-3 py-1': _vm.size === 'xs' },
      ],attrs:{"min":_vm.min,"name":_vm.label,"step":_vm.step,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.model)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('submit', _vm.model)},"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}}),(_vm.error)?_c('p',{directives:[{name:"tippy",rawName:"v-tippy"}],class:[
        'text-xs text-red-500 truncate max-w-full -bottom-6 mt-1 ml-3 text-left',
      ],attrs:{"content":_vm.error}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }