import {
  CartItem,
  Coupon,
  DeliveryPoint,
  ShopField,
  ShopFieldOption,
  ShopProduct,
  ShopProductVariant,
} from "@/types/shop";
import { Commit, Dispatch } from "vuex";
import * as lodash from "lodash";
import { RootActions } from "@/store/index";
import { getPrice } from "@/helpers/shop.helper";
import { User } from "@/types/user";
import VueCookies from "vue-cookies";
import Vue from "vue";

export enum CartActions {
  LOAD_DATA_FROM_COOKIES = "loadDataFromCookies",
  LOAD_RECIPIENT_FROM_COOKIES = "loadRecipientFromCookies",
  LOAD_INVOICE_DATA_FROM_COOKIES = "loadInvoiceDataFromCookies",
  ADD_TO_CART = "addToCart",
  CREATE_NEW_ORDER = "createNewOrder",
}

export enum CartMutations {
  CLEAR_CART = "clearCart",
  CLEAR_ONLY_CART = "clearOnlyCart",
  ADD_TO_CART = "addToCart",
  ADD_COUPON = "addCoupon",
  REMOVE_COUPON = "removeCoupon",
  UPDATE_CART_ITEM = "updateCartItem",
  REMOVE_CART_ITEM = "removeCartItem",
  SET_DELIVERY = "setDelivery",
  SET_DELIVERY_POINT = "setDeliveryPoint",
  UPDATE_RECIPIENT = "updateRecipient",
  UPDATE_INVOICE_DATA = "updateInvoiceData",
}

export interface CartState {
  processingTimeItems: Array<{ label: string; value: string }>;
  recipient?: Partial<User>;
  invoiceData?: Partial<User>;
  cart: Array<CartItem>;
  coupon?: Coupon;
  delivery: "kurier" | "paczkomat";
  deliveryPoint?: DeliveryPoint;
  deliveryOptions: Array<{
    title: string;
    description: string;
    _id: string;
    image: { url: string };
    price: number;
  }>;
}

export const state = (): CartState => ({
  processingTimeItems: [
    {
      label: "1-2 dni",
      value: "1-2 dni",
    },
    {
      label: "3-6 dni",
      value: "3-6 dni",
    },
    {
      label: "7-9 dni",
      value: "7-9 dni",
    },
    {
      label: "10-14 dni",
      value: "10-14 dni",
    },
  ],

  recipient: undefined,
  invoiceData: undefined,
  delivery: "paczkomat",
  deliveryPoint: undefined,
  coupon: undefined,
  cart: [],
  deliveryOptions: [
    {
      title: "Kurier InPost",
      description: "14.99 zł",
      _id: "kurier",
      image: { url: "/images/InPost-Kurier-logo-kwadrat.png" },
      price: 14.99,
    },
    {
      title: "Paczkomat InPost",
      description: "9.99 zł",
      _id: "paczkomat",
      image: { url: "/images/InPost-Paczkomat-logo-kwadrat.png" },
      price: 9.99,
    },
  ],
});

export const getters = {
  maxProcessingTime: (state: CartState) => {
    const productsProcessingTime = state.cart.map(
      (i) => i.product.processingTime || "1-2 dni"
    );
    return [...state.processingTimeItems]
      .reverse()
      .find((i) => productsProcessingTime.includes(i.value))?.label;
  },
  deliveryPoint: (state: CartState) => state.deliveryPoint,
  cartPayload: (state: CartState) =>
    state.cart.map((item) => ({
      product: item.product._id,
      quantity: item.quantity,
      variantKeys: item.variantKeys,
      variantId: item.variant?._id,
    })),

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  totalPrice: (state: CartState, getters) =>
    Math.round(
      (getters.totalProductsPrice +
        getters.deliveryPrice -
        getters.couponDiscount) *
        100
    ) / 100,

  recipient: (state: CartState) => state.recipient,
  invoiceData: (state: CartState) => state.invoiceData,
  coupon: (state: CartState) => state.coupon,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  couponDiscount: (state: CartState, getters) =>
    state.coupon
      ? state.coupon?.discountType === "percent"
        ? Math.round(getters.totalProductsPrice * state.coupon.discountValue) /
          100
        : state.coupon.discountValue
      : 0,
  cartItemQuantity: (state: CartState) =>
    state.cart.reduce((a: number, item: CartItem) => {
      return a + item.quantity;
    }, 0),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  deliveryPrice: (state: CartState, getters) => {
    const price = getters.totalProductsPrice;
    if (price >= 99) return 0;
    return (
      state.deliveryOptions.find((o) => o._id === state.delivery)?.price || 0
    );
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  totalProductsPrice: (state: CartState, getters) =>
    getters.cart.reduce((a: number, item: CartItem) => {
      return a + item.unitPrice * item.quantity;
    }, 0),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  cart: (state: CartState, getters, rootState, rootGetters) =>
    state.cart.map((i) => {
      let unitPrice = getPrice(i.variant ? i.variant : i.product);
      if (!unitPrice) unitPrice = getPrice(i.product);
      const variantLabel = () => {
        if (!i?.variantKeys) return "";
        const keys = Object.keys(i?.variantKeys);
        let string = "";
        keys.forEach((key) => {
          const field = rootGetters["shop/shopFields"].find(
            (f: ShopField) => f._id === key
          );
          const option = field?.options
            ? field.options.find(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                (o: ShopFieldOption) => o._id === i.variantKeys[key]
              )
            : undefined;
          if (field && option)
            string = string + `${field.title}: ${option.title}; `;
        });
        return string;
      };
      return { ...i, unitPrice, variantLabel: variantLabel() };
    }),
};

export const actions = {
  async [CartActions.LOAD_INVOICE_DATA_FROM_COOKIES]({
    rootGetters,
    commit,
  }: {
    commit: Commit;
    rootGetters: any;
  }) {
    const userEmail = rootGetters["auth/authUser"];
    const invoiceData = VueCookies.get(
      "amshop-invoiceData-" + (userEmail ? userEmail : "")
    );
    commit(CartMutations.UPDATE_INVOICE_DATA, invoiceData);
  },
  async [CartActions.LOAD_RECIPIENT_FROM_COOKIES]({
    rootGetters,
    commit,
  }: {
    commit: Commit;
    rootGetters: any;
  }) {
    const userEmail = rootGetters["auth/authUser"];
    const recipient = VueCookies.get(
      "amshop-recipient-" + (userEmail ? userEmail : "")
    );
    commit(CartMutations.UPDATE_RECIPIENT, recipient);
  },
  async [CartActions.LOAD_DATA_FROM_COOKIES]({
    dispatch,
    rootGetters,
    commit,
  }: {
    commit: Commit;
    dispatch: Dispatch;
    rootGetters: any;
  }) {
    dispatch(CartActions.LOAD_RECIPIENT_FROM_COOKIES);
    dispatch(CartActions.LOAD_INVOICE_DATA_FROM_COOKIES);
    const cart = VueCookies.get("amshop-cart");
    const deliveryPoint = VueCookies.get("amshop-deliveryPoint");
    if (cart?.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cart.forEach((item) => {
        const product = rootGetters["shop/visibleShopProducts"].find(
          (i: ShopProduct) => i._id === item.product
        );
        if (product) {
          if (product.variants?.length) {
            const variant = product.variants.find(
              (f: ShopProductVariant) => f._id === item.variantId
            );
            const maxQuantity =
              typeof variant?.quantity !== "undefined"
                ? variant.quantity
                : product.quantity;

            if (variant && maxQuantity > 0)
              dispatch(CartActions.ADD_TO_CART, {
                product: { ...product },
                variantKeys: item.variantKeys,
                variant: variant,
                quantity:
                  maxQuantity >= item.quantity ? item.quantity : maxQuantity,
                isntOpenCart: true,
              });
          } else if (product.quantity > 0) {
            dispatch(CartActions.ADD_TO_CART, {
              product: { ...product },
              variantKeys: undefined,
              variant: undefined,
              quantity:
                product.quantity >= item.quantity
                  ? item.quantity
                  : product.quantity,
              isntOpenCart: true,
            });
          }
        }
      });
    }
    if (deliveryPoint && deliveryPoint.foreign_access_point_id) {
      commit(CartMutations.SET_DELIVERY_POINT, deliveryPoint);
    }
  },
  async [CartActions.ADD_TO_CART](
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: {
      product: ShopProduct;
      variant?: ShopProductVariant;
      variantKeys?: { [key: string]: string };
      quantity?: number;
      isntOpenCart?: boolean;
    }
  ) {
    (Vue as any).$gtag.event("add_to_cart", { payload });

    commit(CartMutations.ADD_TO_CART, {
      ...payload,
      quantity: payload.quantity || 1,
    });
    if (!payload.isntOpenCart)
      dispatch(RootActions.OPEN_CART, null, { root: true });
  },
};

export const mutations = {
  [CartMutations.CLEAR_ONLY_CART](state: CartState) {
    state.cart = [];
  },
  [CartMutations.CLEAR_CART](state: CartState) {
    state.recipient = undefined;
    state.invoiceData = undefined;
    state.cart = [];
    state.coupon = undefined;
    state.deliveryPoint = undefined;
  },
  [CartMutations.ADD_COUPON](state: CartState, payload: Coupon) {
    state.coupon = payload;
  },
  [CartMutations.REMOVE_COUPON](state: CartState) {
    state.coupon = undefined;
  },
  [CartMutations.ADD_TO_CART](state: CartState, payload: CartItem) {
    const cartItem = findCartItem(state, payload);

    if (cartItem) {
      cartItem.quantity = cartItem.quantity + 1;
    } else {
      state.cart.push({ ...payload });
    }
  },
  [CartMutations.UPDATE_CART_ITEM](state: CartState, payload: CartItem) {
    const cartItem = findCartItem(state, payload);

    if (cartItem) {
      cartItem.quantity = payload.quantity;
    } else {
      state.cart.push({ ...payload });
    }
  },
  [CartMutations.UPDATE_RECIPIENT](state: CartState, payload: Partial<User>) {
    if (!payload) state.recipient = undefined;
    else
      state.recipient = {
        displayName: payload.displayName,
        email: payload.email,
        phone: payload.phone,
        postCode: payload.postCode,
        city: payload.city,
        street: payload.street,
      };
  },
  [CartMutations.UPDATE_INVOICE_DATA](
    state: CartState,
    payload?: Partial<User>
  ) {
    if (!payload) state.invoiceData = undefined;
    else
      state.invoiceData = {
        displayName: payload.displayName,
        nip: payload.nip,
        postCode: payload.postCode,
        city: payload.city,
        street: payload.street,
      };
  },
  [CartMutations.REMOVE_CART_ITEM](state: CartState, payload: CartItem) {
    const index = findCartItemIndex(state, payload);
    if (index >= 0) state.cart.splice(index, 1);
  },
  [CartMutations.SET_DELIVERY_POINT](
    state: CartState,
    payload?: DeliveryPoint
  ) {
    state.deliveryPoint = payload;
  },
  [CartMutations.SET_DELIVERY](
    state: CartState,
    payload: "kurier" | "paczkomat"
  ) {
    state.delivery = payload;
  },
};
const findCartItem = (
  state: CartState,
  payload: CartItem
): CartItem | undefined => {
  return state.cart.find((i) => {
    return (
      i.product._id === payload.product._id &&
      lodash.isEqual(
        Object.keys(payload.variantKeys || []),
        Object.keys(i.variantKeys || [])
      ) &&
      lodash.isEqual(
        Object.values(payload.variantKeys || []),
        Object.values(i.variantKeys || [])
      )
    );
  });
};
const findCartItemIndex = (state: CartState, payload: CartItem): number => {
  return state.cart.findIndex((i) => {
    return (
      i.product._id === payload.product._id &&
      lodash.isEqual(
        Object.keys(payload.variantKeys || []),
        Object.keys(i.variantKeys || [])
      ) &&
      lodash.isEqual(
        Object.values(payload.variantKeys || []),
        Object.values(i.variantKeys || [])
      )
    );
  });
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
