import type { Commit } from "vuex";
import { Game } from "@/types/game";
import GameService from "@/services/game.service";

export enum GameActions {
  UPDATE_GAME = "updateGame",
  ADD_NEW_GAME = "addNewGame",
  FETCH_GAMES_LIST = "fetchGamesList",
  CONFIRM_GAME = "confirmGame",
  DELETE_GAME = "deleteGame",
}

export enum GameMutations {
  SET_GAMES_LIST = "setGamesList",
}
export interface GameState {
  games: Array<Game>;
}
export const state = (): GameState => ({
  games: [],
});

export const getters = {
  games: (state: GameState) => state.games,
};

export const actions = {
  async [GameActions.FETCH_GAMES_LIST]({ commit }: { commit: Commit }) {
    const response = await GameService.getGamesList();
    commit(GameMutations.SET_GAMES_LIST, response.games);
  },
};

export const mutations = {
  [GameMutations.SET_GAMES_LIST](state: GameState, payload: Array<Game>) {
    state.games = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
