var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"relative flex pb-10 sm:pb-0"},[_c('div',{staticClass:"flex-shrink-0 flex justify-center items-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
        _vm.product && _vm.product.images?.length
          ? _vm.product.images[0].thumb_url
            ? _vm.product.images[0].thumb_url
            : _vm.product.images[0].url
          : '/images/default-image.png'
      ),expression:"\n        product && product.images?.length\n          ? product.images[0].thumb_url\n            ? product.images[0].thumb_url\n            : product.images[0].url\n          : '/images/default-image.png'\n      "}],staticClass:"object-cover w-16 h-16 rounded-lg",attrs:{"alt":(_vm.product?.title || 'Produkt') + ' - zdjęcie'}})]),_c('div',{staticClass:"flex-1 flex ml-5"},[_c(!_vm.product ? 'div' : 'router-link',{tag:"component",staticClass:"relative sm:grid sm:grid-cols-2 sm:gap-x-5 items-center w-full transition-all",class:{ 'hover:opacity-60': _vm.product },attrs:{"to":'/produkt/' +
        _vm.product?._id +
        '-' +
        (_vm.product ? _vm.convertStringToUrl(_vm.product?.title) : '')}},[_c('div',{staticClass:"pr-9 sm:pr-5"},[_c('p',{staticClass:"text-base font-bold text-gray-900 leading-5 mb-1"},[_vm._v(" "+_vm._s(_vm.cartItem.productTitle)+" ")]),(_vm.cartItem.variantTitle)?_c('p',{staticClass:"mt-1.5 font-medium text-xs text-gray-500 mb-0"},[_vm._v(" "+_vm._s(_vm.cartItem.variantTitle)+" ")]):_vm._e()]),_c('div',{staticClass:"flex items-end justify-between mt-3 sm:justify-end sm:pr-10 sm:items-center sm:mt-0"},[_c('p',{staticClass:"flex-shrink-0 w-20 mb-1 text-base font-bold text-left text-gray-900 sm:text-right sm:order-2 sm:ml-2"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.cartItem.price))+" ")]),_c('div',{staticClass:"sm:order-1 flex items-center mb-1 text-sm"},[_c('div',{staticClass:"w-5 mr-1 text-right"},[_vm._v(" "+_vm._s(_vm.cartItem.quantity)+" ")]),_c('div',{staticClass:"truncate w-20"},[_vm._v("x "+_vm._s(_vm.getUnitPrice(_vm.cartItem)))])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }