
import Vue from "vue";
import type { PropType } from "vue";
import ImagePreview from "primevue/imagepreview";
import { ShopFieldOption } from "@/types/shop";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ClarityCheckbox from "@/components/clarity/ClarityCheckbox.vue";
import Galleria from "primevue/galleria";

export default Vue.extend({
  name: "ClarityRadio",
  props: {
    imagePreview: { type: Boolean, default: true },
    size: { type: String, default: "md" },
    imgWidth: { type: String, default: "" },
    label: { type: undefined, default: "" },
    value: { type: undefined, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    items: {
      type: Array as PropType<Array<ShopFieldOption>>,
      default: () => [],
    },
  },
  data: () => ({
    faEye,
    activeIndex: 0,
    displayCustom: false,
  }),

  components: { Galleria, FontAwesomeIcon },
  computed: {
    showImg(): boolean {
      return !!this.items.find((i) => i.image);
    },
  },
  methods: {
    imageClick(index: number): void {
      this.activeIndex = index;
      this.displayCustom = true;
    },
  },
});
