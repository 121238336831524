
import { defineComponent } from "vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import ClarityInput from "@/components/clarity/ClarityInput.vue";
import { email, required } from "vuelidate/lib/validators";
import { getErrorMessage } from "@/helpers/validation";
import ClarityTextArea from "@/components/clarity/ClarityTextArea.vue";
import ShopService from "@/services/shop.service";

export default defineComponent({
  name: "ContactView",
  components: { ClarityTextArea, ClarityInput, ClarityButton },
  methods: {
    getErrorMessage,
    async sendMessage(): Promise<void> {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$noty.error(this.$t("formContainsErrors") as string);
        return;
      }
      this.isLoading = true;
      try {
        await ShopService.sendUserMessage({
          email: this.email,
          message: this.message,
        });
        this.$noty.success("Wiadomość została wysłana");
        this.email = "";
        this.message = "";
        this.formError = "";
      } catch (e: any) {
        this.$noty.error(this.$t("formContainsErrors") as string);
        this.formError = this.$t(e.message) as string;
      } finally {
        this.isLoading = false;
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    message: {
      required,
    },
  },
  data: function () {
    return {
      email: "",
      message: "",
      formError: "",
      isLoading: false,
    };
  },
});
