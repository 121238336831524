export enum UserRole {
  ADMIN = "admin",
  SHOP_ADMIN = "shop-admin",
  MODERATOR = "moderator",
  SHOP_MODERATOR = "shop-moderator",
  USER = "user",
}

export interface User {
  _id?: string;
  id?: string; //todo: choose one id
  displayName: string;
  nip?: string;
  email: string;
  phone?: string;
  avatar?: string;
  roles?: Array<UserRole>;
  approved: boolean;
  createdAt?: string;
  approvedAt?: string;
  postCode?: string;
  street?: string;
  city?: string;
}
