
import Vue from "vue";
import type { PropType } from "vue";
import Dropdown from "primevue/dropdown";

export default Vue.extend({
  name: "ClaritySelect",
  props: {
    size: { type: String, default: "md" },
    label: { type: undefined, default: "" },
    value: { type: undefined, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    filter: { type: Boolean, default: true },
    errorMessage: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    items: {
      type: Array as PropType<Array<{ value: any; label: string }>>,
      default: () => [],
    },
  },
  components: { Dropdown },
});
