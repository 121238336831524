
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Loader from "@/components/Loader.vue";
import { ModuleNames } from "@/store";
import ClarityNavBar from "@/components/clarity/ClarityNavBar.vue";
import ClarityFooter from "@/components/clarity/ClarityFooter.vue";
import ClarityCart from "@/components/clarity/ClarityCart.vue";
import UserService from "@/services/user.service";
import ShopService from "@/services/shop.service";
import VueCookies from "vue-cookies";
import ClarityModal from "@/components/clarity/modals/ClarityModal.vue";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default Vue.extend({
  metaInfo(): { titleTemplate: string; meta: any } {
    return {
      titleTemplate:
        (this.$route?.meta?.name ? this.$route.meta.name + " | " : "") +
        this.companyName,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: (
            (this.$route?.meta?.name ? this.$route.meta.name + " | " : "") +
            this.description
          ).substring(0, 160),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            (this.$route?.meta?.name ? this.$route.meta.name + " | " : "") +
            this.companyName,
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            (this.$route?.meta?.name ? this.$route.meta.name + " | " : "") +
            this.description,
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content:
            (this.$route?.meta?.name ? this.$route.meta.name + " | " : "") +
            this.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${window.location.href}`,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${window.location.origin}/logo2.png`,
        },
      ],
    };
  },
  components: {
    FontAwesomeIcon,
    ClarityButton,
    ClarityModal,
    ClarityCart,
    ClarityFooter,
    ClarityNavBar,
    Loader,
  },
  computed: {
    ...mapGetters({ isLoading: "isLoading", headers: "headers" }),
    ...mapState({
      companyName: "companyName",
      description: "description",
      lastVisitedRoute: "lastVisitedRoute",
    }),
    ...mapGetters(ModuleNames.auth, {
      isAuthenticated: "isAuthenticated",
      isApproved: "isApproved",
      authUser: "authUser",
    }),
    ...mapGetters(ModuleNames.shop, {
      shopCategories: "shopCategories",
      shopFields: "shopFields",
      shopProducts: "shopProducts",
      shopProductsBasic: "shopProductsBasic",
    }),
    ...mapGetters(ModuleNames.blog, { blogs: "blogs" }),
    ...mapGetters(ModuleNames.page, { pages: "pages" }),
    ...mapGetters(ModuleNames.cart, {
      cartPayload: "cartPayload",
      recipient: "recipient",
      invoiceData: "invoiceData",
      deliveryPoint: "deliveryPoint",
    }),
  },
  data: function () {
    return {
      acceptedCookies: true,
      faCookieBite,
      closeAdBlockModal: {
        visible: false,
        title: this.$t("detectedAdBlockTitle") as string,
        description: this.$t("detectedAdBlockDesc") as string,
      },
    };
  },
  async created(): Promise<void> {
    const acceptedCookies = VueCookies.get("amshop-cookies");
    if (acceptedCookies) {
      this.acceptedCookies = true;
    } else {
      this.acceptedCookies = false;
    }

    const promises = [];
    if (!this.shopProductsBasic?.length) {
      promises.push(this.fetchShopProductsBasic());
    }
    if (!this.headers?.length) {
      promises.push(this.fetchHeaders());
    }
    if (!this.blogs?.length) {
      promises.push(this.fetchBlogs());
    }

    if (!this.shopCategories?.length) {
      promises.push(this.fetchShopCategories());
    }
    if (!this.shopFields?.length) {
      promises.push(this.fetchShopFields());
    }
    if (!this.shopProducts?.length) {
      promises.push(this.fetchShopProducts());
    }
    if (!this.pages?.length) {
      promises.push(this.fetchPages());
    }
    if (promises.length) {
      try {
        this.setIsLoading(true);
        await Promise.all(promises);
      } catch (e: any) {
        console.error(e);
      } finally {
        this.setIsLoading(false);
      }
    }
    this.loadDataFromCookies();
  },
  methods: {
    ...mapActions(["updateBreadcrumbs", "fetchHeaders"]),
    ...mapMutations(["setIsLoading"]),
    ...mapActions(ModuleNames.cart, [
      "loadDataFromCookies",
      "loadInvoiceDataFromCookies",
      "loadRecipientFromCookies",
    ]),
    ...mapActions(ModuleNames.shop, [
      "fetchShopCategories",
      "fetchShopFields",
      "fetchShopProducts",
      "fetchShopProductsBasic",
    ]),
    ...mapActions(ModuleNames.blog, ["fetchBlogs"]),
    ...mapActions(ModuleNames.page, ["fetchPages"]),
    ...mapMutations(ModuleNames.cart, ["updateRecipient", "updateInvoiceData"]),
    acceptCookies(): void {
      VueCookies.set("amshop-cookies", true, { expires: "1y" });
      this.acceptedCookies = true;
    },
    async sendConfirmationEmail(): Promise<void> {
      try {
        this.setIsLoading(true);
        await UserService.sendAgainConfirmationEmail();
        this.$noty.success("Wysłano email. Sprawdź skrzynkę pocztową");
      } catch (e: any) {
        this.$noty.error("Error: " + e.message);
      } finally {
        this.setIsLoading(false);
      }
    },
    checkPermissions(): void {
      if (this.isAuthenticated) {
        if (this.lastVisitedRoute && this.$route.name === "login") {
          this.$router.push(this.lastVisitedRoute);
        } else if (this.$route.meta?.isGuest) {
          if (this.$route.query.return) {
            this.$router.push({ name: this.$route.query.return as string });
          } else {
            this.$router.push({ name: "account" });
          }
        }
      } else if (!this.isAuthenticated && this.$route.meta?.isAuthenticated) {
        this.$router.push({ name: "login" });
      }
    },
  },
  watch: {
    cartPayload(): void {
      VueCookies.set("amshop-cart", this.cartPayload, { expires: "7d" });
    },
    recipient(): void {
      VueCookies.set(
        "amshop-recipient-" +
          (this.authUser?.email ? this.authUser?.email : ""),
        this.recipient ? this.recipient : null,
        { expires: "7d" }
      );
    },
    invoiceData(): void {
      VueCookies.set(
        "amshop-invoiceData-" +
          (this.authUser?.email ? this.authUser?.email : ""),
        this.invoiceData ? this.invoiceData : null,
        { expires: "7d" }
      );
    },
    deliveryPoint(): void {
      VueCookies.set("amshop-deliveryPoint", this.deliveryPoint, {
        expires: "7d",
      });
    },
    isAuthenticated: {
      immediate: true,
      handler(): void {
        this.loadRecipientFromCookies();
        this.loadInvoiceDataFromCookies();
        this.checkPermissions();
      },
    },
    $route: {
      immediate: true,
      handler(): void {
        this.checkPermissions();
        this.updateBreadcrumbs(this.$route);
      },
    },
  },
});
