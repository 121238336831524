
import Vue, { PropType } from "vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default Vue.extend({
  name: "ClarityModal",
  props: {
    onConfirm: { type: Function as PropType<() => void> },
    showClose: { type: Boolean, default: true },
  },
  components: {
    SvgIcon,
  },
  methods: {
    async close(): Promise<void> {
      this.$emit("close");
    },
  },
});
