
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import Vue from "vue";
import { convertStringToUrl } from "@/helpers/url";
import { mapGetters, mapState } from "vuex";
import { ModuleNames } from "@/store";
import { ShopProduct } from "@/types/shop";
import ProductThumbnail from "@/components/ProductThumbnail.vue";
import Galleria from "primevue/galleria";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Blog } from "@/types/main";

export default Vue.extend({
  name: "HomeView",
  methods: { convertStringToUrl },
  components: {
    ProductThumbnail,
    Galleria,
    VueSlickCarousel,
    FontAwesomeIcon,
  },
  data: function () {
    return {
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      faChevronLeft,
      faChevronRight,
    };
  },
  computed: {
    ...mapState(ModuleNames.shop, { shopProducts: "shopProducts" }),
    ...mapGetters(ModuleNames.shop, {
      visibleShopProducts: "visibleShopProducts",
      productsIsLoading: "productsIsLoading",
      shopProductsBasic: "shopProductsBasic",
    }),
    ...mapState({
      description: "description",
    }),
    ...mapGetters(ModuleNames.blog, {
      blogs: "blogs",
      blogsIsLoading: "blogsIsLoading",
    }),
    ...mapGetters({ headers: "headers" }),
    lastAddedShopProducts(): Array<ShopProduct> {
      if (!this.visibleShopProducts?.length) {
        return [];
      }
      const filtered = [...this.visibleShopProducts]
        ?.filter((i) => i.thumbnail.totalQuantity)
        .sort((a, b) => {
          return (a.createdAt || "") < (b.createdAt || "") ? 0 : -1;
        });
      return filtered.length >= 10 ? [...filtered.slice(0, 10)] : [...filtered];
    },
    saleShopProducts(): Array<ShopProduct> {
      if (!this.visibleShopProducts?.length) {
        return [];
      }
      const filtered = [...this.visibleShopProducts]?.filter((i) => i.isSale);
      return filtered.length >= 10 ? [...filtered.slice(0, 10)] : [...filtered];
    },
    premiumShopProducts(): Array<ShopProduct> {
      if (!this.visibleShopProducts?.length) {
        return [];
      }
      const filtered = [...this.visibleShopProducts]?.filter(
        (i) => i.isPremium
      );
      return filtered.length >= 10 ? [...filtered.slice(0, 10)] : [...filtered];
    },
    lastBlogs(): Array<Blog> {
      if (!this.blogs) return [];
      return this.blogs.length >= 3
        ? [...this.blogs.slice(0, 3)]
        : [...this.blogs];
    },
  },
});
