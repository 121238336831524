
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { ModuleNames } from "@/store";
import { convertStringToUrl } from "@/helpers/url";
import { ShopProduct } from "@/types/shop";
import { Blog } from "@/types/main";
import SvgIcon from "@/components/SvgIcon.vue";
import ProductThumbnail from "@/components/ProductThumbnail.vue";
import {
  faChevronLeft,
  faChevronRight,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { UserRole } from "@/types/user";

export default defineComponent({
  name: "BlogView",
  metaInfo(): { titleTemplate: string; meta: any } {
    return {
      titleTemplate:
        (this.blog?.title ? this.blog?.title + " | " : "") + this.companyName,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: (
            (this.blog?.title ? this.blog?.title + " | " : "") +
            this.description
          ).substring(0, 160),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            (this.blog?.title ? this.blog?.title + " | " : "") +
            this.companyName,
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            (this.blog?.title ? this.blog?.title + " | " : "") +
            this.description,
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content:
            (this.blog?.title ? this.blog?.title + " | " : "") +
            this.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${window.location.href}`,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.img,
        },
      ],
    };
  },

  components: { ProductThumbnail, SvgIcon, VueSlickCarousel, FontAwesomeIcon },
  methods: {
    convertStringToUrl,
  },
  data: function () {
    return {
      faTimesCircle,
      faChevronRight,
      faChevronLeft,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(ModuleNames.shop, {
      visibleShopProducts: "visibleShopProducts",
    }),
    ...mapGetters(ModuleNames.blog, {
      blogs: "blogs",
      blogsIsLoading: "blogsIsLoading",
    }),
    ...mapGetters(ModuleNames.auth, { authUser: "authUser" }),
    ...mapState({ companyName: "companyName", description: "description" }),
    img(): string {
      let logo = this.blog?.image?.url;
      const defaultLogo = window.location.origin + "/logo2.png";
      if (logo && logo.startsWith("/")) logo = window.location.origin + logo;
      return logo ? logo : defaultLogo;
    },
    showEditButton(): boolean {
      return this.authUser?.roles?.includes(UserRole.SHOP_MODERATOR);
    },
    blog(): Blog | undefined {
      return this.blogs?.find(
        (blog: Blog) => this.$route.params.id.split("-")?.[0] === blog._id
      );
    },
    blogProducts(): Array<ShopProduct> {
      if (!this.blog?.hasSlides && !this.blog?.slides?.length) return [];
      return this.visibleShopProducts.filter((p: ShopProduct) =>
        this.blog?.slides?.includes(p._id)
      );
    },
  },
});
